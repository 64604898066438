import * as React from 'react';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  AppState,
  Platform,
  StatusBar,
} from 'react-native';
import { Provider as ThemeProvider } from '@draftbit/ui';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  createClient,
  AnalyticsProvider,
} from '@segment/analytics-react-native';

import AppNavigator from './AppNavigator';
import Draftbit from './themes/Draftbit.js';
import cacheAssetsAsync from './config/cacheAssetsAsync';
import { GlobalVariableProvider } from './config/GlobalVariableContext';
import { useFonts } from 'expo-font';
import Fonts from './config/Fonts.js';
import { TourGuideProvider } from 'rn-tourguide';
SplashScreen.preventAutoHideAsync();
const segmentClient = createClient({
  writeKey: 'YQV0ACjnT45ggzNYVCqyBz89OMMkBPSK',
  trackAppLifecycleEvents: true,
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const queryClient = new QueryClient();

const App = () => {
  const [areAssetsCached, setAreAssetsCached] = React.useState(false);

  const [fontsLoaded] = useFonts({
    Inter_500Medium: Fonts.Inter_500Medium,
    Nunito_400Regular: Fonts.Nunito_400Regular,
    Poppins_400Regular: Fonts.Poppins_400Regular,
    Poppins_700Bold: Fonts.Poppins_700Bold,
    SpaceGrotesk_600SemiBold: Fonts.SpaceGrotesk_600SemiBold,
    SpaceGrotesk_400Regular: Fonts.SpaceGrotesk_400Regular,
    SpaceGrotesk_700Bold: Fonts.SpaceGrotesk_700Bold,
    SpaceGrotesk_500Medium: Fonts.SpaceGrotesk_500Medium,
    SpaceGrotesk_300Light: Fonts.SpaceGrotesk_300Light,
  });

  React.useEffect(() => {
    async function prepare() {
      try {
        await cacheAssetsAsync();
      } catch (e) {
        console.warn(e);
      } finally {
        setAreAssetsCached(true);
      }
    }

    prepare();
  }, []);

  const isReady = areAssetsCached && fontsLoaded;
  const onLayoutRootView = React.useCallback(async () => {
    if (isReady) {
      await SplashScreen.hideAsync();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {Platform.OS === 'android' ? (
        <StatusBar backgroundColor={'#141317'} />
      ) : null}
      <AnalyticsProvider client={segmentClient}>
        <SafeAreaProvider
          initialMetrics={initialWindowMetrics}
          onLayout={onLayoutRootView}
        >
          <GlobalVariableProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider
                themes={[Draftbit]}
                breakpoints={{}}
                initialThemeName={Draftbit.name}
              >
                <TourGuideProvider
                  {...{
                    backdropColor: 'rgba(50, 50, 100, 0.9)',
                    androidStatusBarVisible: true,
                    preventOutsideInteraction: true,
                  }}
                >
                  <AppNavigator />
                </TourGuideProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </GlobalVariableProvider>
        </SafeAreaProvider>
      </AnalyticsProvider>
    </>
  );
};

export default App;
