import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as TagsAPIApi from '../apis/TagsAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AuthComponents from '../custom-files/AuthComponents';
import * as CustomCode from '../custom-files/CustomCode';
import * as Lib from '../custom-files/Lib';
import * as TagLists from '../custom-files/TagLists';
import * as TourGuide from '../custom-files/TourGuide';
import formatGoToClickedTagPageScreen from '../global-functions/formatGoToClickedTagPageScreen';
import goBack from '../global-functions/goBack';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import trackAction from '../global-functions/trackAction';
import updateGlobalIsLiked from '../global-functions/updateGlobalIsLiked';
import updateGlobalLikes from '../global-functions/updateGlobalLikes';
import updateGlobalShares from '../global-functions/updateGlobalShares';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const ClickedTagPageScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [canStartTour, setCanStartTour] = React.useState(false);
  const [commentsCount, setCommentsCount] = React.useState(0);
  const [emailValue, setEmailValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLikedByUser, setIsLikedByUser] = React.useState(false);
  const [isLiking, setIsLiking] = React.useState(false);
  const [isLoginWithGoogle, setIsLoginWithGoogle] = React.useState(false);
  const [isSharing, setIsSharing] = React.useState(false);
  const [passwordValue, setPasswordValue] = React.useState('');
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);
  const isLikedCountDefined = (Variables, id) => {
    return Variables.likesCache?.[id] !== undefined;
  };

  const isShareCountDefined = id => {
    return Variables.sharesCache?.[id] !== undefined;
  };

  const isLikedByUserDefined = id => {
    return Variables.isLikedByUserCache?.[id] !== undefined;
  };

  const formatTrackProductLinkData = (product_url, product_name) => {
    return {
      product_name,
      product_url,
    };
  };

  const getUpperScreenHeight = () => {
    return dimensions.height - (Platform.OS === 'ios' ? 240 : 160);
  };

  const showModalIn = (setGlobalVariableValue, duration) => {
    if (Platform.OS === 'web') {
      const timeout = setTimeout(() => {
        setGlobalVariableValue({
          key: 'showLoginModal',
          value: true,
        });
      }, duration || 30000);
    }
  };

  const validEmail = () => {
    if (emailValue === '') return true;

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
  };

  const validForm = () => {
    if (!emailValue) {
      setErrorMessage('Please enter your email');
      return false;
    }
    if (!passwordValue) {
      setErrorMessage('Please enter your password');
      return false;
    }

    if (validEmail(emailValue) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const showLikeCount = likeCount => {
    return !isLiking && likeCount > 0;
  };

  const showShareCount = shareCount => {
    return !isSharing && shareCount > 0;
  };

  const subtractPadding = (width, padding) => {
    return (width || 0) - (padding || 0) * 2;
  };

  const getSharesCount = (Variables, id) => {
    return Variables.sharesCache?.[id] || 0;
  };

  const getLikesCount = (Variables, id) => {
    return Variables.likesCache?.[id] || 0;
  };

  const getIsLiked = (Variables, id) => {
    return Variables.isLikedByUserCache?.[id];
  };

  const formatTrackData = id => {
    return {
      id: id,
    };
  };

  // This function starts the Tour for the co-pilot.  Check inline code for more details
  const startTour = setGlobalVariableValue => {
    // if(start)

    setGlobalVariableValue({
      key: 'TOURS_TO_SHOW',
      value: Variables?.TOURS_TO_SHOW.filter(t => t !== 'ClickedTagPage'),
    });

    setTimeout(() => {
      setCanStartTour(true);
    }, 500);
  };

  const facetCreator = (key, value) => {
    return [`${key}:${value}`];
  };
  React.useEffect(() => {
    pushToHistory(
      Variables,
      setGlobalVariableValue,
      segment,
      'ClickedTagPageScreen',
      formatGoToClickedTagPageScreen(props.route?.params?.tagID)
    );
  }, [props.route?.params?.tagID]);

  // Use Hooks to control!
  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    stop, // a function  to stopping it
    eventEmitter, // an object for listening some events
    tourKey,
  } = Lib.useTourGuideController();

  React.useEffect(() => {
    if (canStartTour) {
      start();
    }
  }, [canStartTour]);

  // const handleOnStart = () => console.log('start')
  // const handleOnStop = () => console.log('stop')
  // const handleOnStepChange = () => console.log(`stepChange`)

  // React.useEffect(() => {
  //   eventEmitter.on('start', handleOnStart)
  //   eventEmitter.on('stop', handleOnStop)
  //   eventEmitter.on('stepChange', handleOnStepChange)

  //   return () => {
  //     eventEmitter.off('start', handleOnStart)
  //     eventEmitter.off('stop', handleOnStop)
  //     eventEmitter.off('stepChange', handleOnStepChange)
  //   }
  // }, [])
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SHOW_TOUR',
        value: null,
      });
      undefined;
      /* hidden 'Set Variable' action */
      pushToHistory(
        Variables,
        setGlobalVariableValue,
        segment,
        'ClickedTagPageScreen',
        formatGoToClickedTagPageScreen(props.route?.params?.tagID ?? 1345)
      );
      if (!Constants['USER']?.id) {
        showModalIn(setGlobalVariableValue, 20000);
      }
      if (
        navigateToCompleteOnboarding(
          navigation,
          setGlobalVariableValue,
          Constants['USER']
        )
      ) {
        navigation.navigate('SignUpScreen', { completeOnBoarding: true });
      }
      if (
        (Constants['SHOW_TOUR'] === -1 ||
          Constants['SHOW_TOUR'] === 1 ||
          Constants['SHOW_TOUR'] === null) &&
        !(Platform.OS === 'web')
      ) {
        if (Constants['TOURS_TO_SHOW'].includes('ClickedTagPage')) {
          startTour(setGlobalVariableValue);
        } else {
        }
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: { minWidth: Breakpoints.BigScreen, value: 'auto' },
          backgroundColor: palettes.App.BackgroundPurple,
        },
        dimensions.width
      )}
    >
      <>
        {Constants['showLoginModal'] ? null : (
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow']
              .props}
            elevation={3}
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow']
                .style,
              dimensions.width
            )}
          >
            {/* BackButton */}
            <Touchable
              onPress={() => {
                try {
                  goBack(navigation, Variables, setGlobalVariableValue);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Icon
                color={palettes.App['Custom Color_2']}
                name={'AntDesign/leftcircle'}
                size={40}
              />
            </Touchable>
          </Surface>
        )}
      </>
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              backgroundColor: palettes.App.BackgroundPurple,
              justifyContent: 'flex-end',
            },
            dimensions.width
          )}
        >
          <>
            {!Constants['USER']?.id ? null : (
              <TagsAPIApi.FetchGetTagDetailsGET
                handlers={{
                  onData: fetchData => {
                    try {
                      setIsLikedByUser(fetchData?._is_liked_by_user);
                      if (
                        !isLikedCountDefined(
                          Variables,
                          props.route?.params?.tagID ?? 1345
                        )
                      ) {
                        updateGlobalLikes(
                          Variables,
                          setGlobalVariableValue,
                          props.route?.params?.tagID ?? 1345,
                          fetchData?._likes_of_tags
                        );
                      }
                      if (
                        !isShareCountDefined(props.route?.params?.tagID ?? 1345)
                      ) {
                        updateGlobalShares(
                          Variables,
                          setGlobalVariableValue,
                          props.route?.params?.tagID ?? 1345,
                          fetchData?._share_count
                        );
                      }
                      if (
                        !isLikedByUserDefined(
                          props.route?.params?.tagID ?? 1345
                        )
                      ) {
                        updateGlobalIsLiked(
                          Variables,
                          setGlobalVariableValue,
                          props.route?.params?.tagID ?? 1345,
                          fetchData?._is_liked_by_user
                        );
                      }
                      setCommentsCount(fetchData?._comments_of_tags);
                      trackAction(Variables, segment, 'TagView', fetchData);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                tag_id={props.route?.params?.tagID ?? 1345}
              >
                {({ loading, error, data, refetchGetTagDetails }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* ResponsiveWrapper */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)[
                          'ResponsiveWrapperView'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)[
                              'ResponsiveWrapperView'
                            ].style,
                            {
                              marginTop: 25,
                              paddingLeft: 11,
                              paddingRight: 11,
                              width: dimensions.width,
                              zIndex: 1,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* UpperScreen */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: [
                                { minWidth: Breakpoints.Mobile, value: '100%' },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: getUpperScreenHeight(),
                                },
                              ],
                              marginBottom: 12,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1, justifyContent: 'flex-end' },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 3,
                                  borderColor: palettes.App['Border Color'],
                                  flex: 1,
                                  justifyContent: 'flex-end',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 2 */}
                              <Link
                                accessible={true}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await WebBrowser.openBrowserAsync(
                                        `${fetchData?._tag_retail_details?.product_link}`
                                      );
                                      trackAction(
                                        Variables,
                                        segment,
                                        'Product Link Clicked',
                                        formatTrackProductLinkData(
                                          fetchData?._tag_retail_details
                                            ?.product_link,
                                          fetchData?._tag_retail_details
                                            ?.product_name
                                        )
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link']
                                      .style,
                                    {
                                      color: palettes.App['Custom Color_2'],
                                      fontFamily: 'SpaceGrotesk_400Regular',
                                      fontSize: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Click for Product Link'}
                              />
                              <Touchable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await WebBrowser.openBrowserAsync(
                                        `${fetchData?._tag_retail_details?.product_link}`
                                      );
                                      trackAction(
                                        Variables,
                                        segment,
                                        'Tag Image Clicked',
                                        formatTrackProductLinkData(
                                          fetchData?._tag_retail_details
                                            ?.product_link,
                                          fetchData?._tag_retail_details
                                            ?.product_name
                                        )
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                activeOpacity={0.8}
                                disabledOpacity={0.8}
                                style={StyleSheet.applyWidth(
                                  { height: '90%', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flex: 1,
                                      justifyContent: 'flex-end',
                                      maxWidth: 1024,
                                      width: subtractPadding(
                                        dimensions.width,
                                        12
                                      ),
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    {...GlobalStyles.ImageStyles(theme)[
                                      'ResponsiveProductImage'
                                    ].props}
                                    resizeMode={'contain'}
                                    source={{
                                      uri: `${
                                        (
                                          fetchData?._tag_photos &&
                                          (fetchData?._tag_photos)[0]
                                        )?.tag_photo?.url
                                      }`,
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'ResponsiveProductImage'
                                        ].style,
                                        {
                                          height: '100%',
                                          maxHeight: (
                                            fetchData?._tag_photos &&
                                            (fetchData?._tag_photos)[0]
                                          )?.tag_photo?.meta?.height,
                                          maxWidth: (
                                            fetchData?._tag_photos &&
                                            (fetchData?._tag_photos)[0]
                                          )?.tag_photo?.meta?.width,
                                          width: '100%',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </Touchable>
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginTop: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 25,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 12 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* TagText */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    overflow: 'hidden',
                                    paddingBottom: 5,
                                    paddingLeft: 5,
                                    paddingTop: 3,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      maxWidth: [
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 600,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 300,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* CopilotWrapper */}
                                  <Utils.CustomCodeErrorBoundary>
                                    <TourGuide.WrapperZone
                                      text={'Click here for the product link'}
                                      zone={1}
                                      borderRadius={6}
                                      maskOffset={6}
                                      tourKey={tourKey}
                                    >
                                      {/* Product Name Pressable */}
                                      <Pressable
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              if (
                                                fetchData?._tag_retail_details
                                                  ?.product_link
                                              ) {
                                                await WebBrowser.openBrowserAsync(
                                                  `${fetchData?._tag_retail_details?.product_link}`
                                                );
                                              }
                                              if (
                                                fetchData?._tag_retail_details
                                                  ?.product_link
                                              ) {
                                                trackAction(
                                                  Variables,
                                                  segment,
                                                  'Product Link Click',
                                                  formatTrackProductLinkData(
                                                    fetchData
                                                      ?._tag_retail_details
                                                      ?.product_link,
                                                    fetchData
                                                      ?._tag_retail_details
                                                      ?.product_name
                                                  )
                                                );
                                              }
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flexDirection: 'row' },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: palettes.App.White,
                                                  fontSize: 22,
                                                  textAlign: 'left',
                                                  textDecorationLine:
                                                    'underline',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              fetchData?._tag_retail_details
                                                ?.product_name
                                            }
                                          </Text>
                                          <Icon
                                            color={palettes.App.White}
                                            name={'Entypo/price-tag'}
                                            size={30}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 8 },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </Pressable>
                                    </TourGuide.WrapperZone>
                                  </Utils.CustomCodeErrorBoundary>
                                  {/* CopilotWrapper 2 */}
                                  <Utils.CustomCodeErrorBoundary>
                                    <TourGuide.WrapperZone
                                      text={
                                        'Click here to see more tags from this brand'
                                      }
                                      zone={2}
                                      borderRadius={6}
                                      maskOffset={6}
                                      tourKey={tourKey}
                                    >
                                      {/* CreativeSourceLink */}
                                      <Link
                                        accessible={true}
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'BottomTabNavigator',
                                              {
                                                screen: 'HomeStackNavgiator',
                                                params: {
                                                  screen: 'HomepageScreen',
                                                  params: {
                                                    pid: null,
                                                    query: null,
                                                    initialFacets: facetCreator(
                                                      '_tag_retail_details.brand',
                                                      fetchData
                                                        ?._tag_retail_details
                                                        ?.brand
                                                    ),
                                                  },
                                                },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        {...GlobalStyles.LinkStyles(theme)[
                                          'Link'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.LinkStyles(theme)[
                                              'Link'
                                            ].style,
                                            {
                                              color:
                                                palettes.App['Custom Color_2'],
                                              fontFamily:
                                                'SpaceGrotesk_400Regular',
                                              fontSize: 18,
                                              marginTop: 8,
                                              textDecorationLine: 'underline',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        title={`${fetchData?._tag_retail_details?.brand}`}
                                      />
                                    </TourGuide.WrapperZone>
                                  </Utils.CustomCodeErrorBoundary>
                                  {/* CopilotWrapper 3 */}
                                  <Utils.CustomCodeErrorBoundary>
                                    <TourGuide.WrapperZone
                                      text={
                                        'Click here to see more tags from this show'
                                      }
                                      zone={3}
                                      borderRadius={6}
                                      maskOffset={6}
                                      tourKey={tourKey}
                                    >
                                      {/* Series and Episode */}
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              palettes.Brand['Strong Inverse'],
                                            fontFamily:
                                              'SpaceGrotesk_400Regular',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 18,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 22,
                                              },
                                            ],
                                            marginTop: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* SeriesLink */}
                                        <Link
                                          accessible={true}
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'BottomTabNavigator',
                                                {
                                                  screen: 'HomeStackNavgiator',
                                                  params: {
                                                    screen: 'HomepageScreen',
                                                    params: {
                                                      pid: null,
                                                      query: null,
                                                      initialFacets:
                                                        facetCreator(
                                                          '_tag_content_details.series_name',
                                                          fetchData
                                                            ?._tag_content_details
                                                            ?.series_name
                                                        ),
                                                    },
                                                  },
                                                }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          {...GlobalStyles.LinkStyles(theme)[
                                            'Link'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.LinkStyles(theme)[
                                                'Link'
                                              ].style,
                                              {
                                                color:
                                                  palettes.App[
                                                    'Custom Color_2'
                                                  ],
                                                textDecorationLine: 'underline',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          title={`${fetchData?._tag_content_details?.series_name} `}
                                        />
                                        {/* EpisodeLink */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {' '}
                                          {
                                            fetchData?._tag_content_details
                                              ?.episode
                                          }
                                        </Text>
                                        {null}
                                      </Text>
                                    </TourGuide.WrapperZone>
                                  </Utils.CustomCodeErrorBoundary>
                                  {/* CopilotWrapper 4 */}
                                  <Utils.CustomCodeErrorBoundary>
                                    <TourGuide.WrapperZone
                                      text={
                                        'Click here to see more tags from this actor'
                                      }
                                      zone={4}
                                      borderRadius={6}
                                      maskOffset={6}
                                      tourKey={tourKey}
                                    >
                                      {/* ActorNameLink */}
                                      <Link
                                        accessible={true}
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'BottomTabNavigator',
                                              {
                                                screen: 'HomeStackNavgiator',
                                                params: {
                                                  screen: 'HomepageScreen',
                                                  params: {
                                                    pid: null,
                                                    query: null,
                                                    initialFacets: facetCreator(
                                                      '_tag_content_details.actor',
                                                      fetchData
                                                        ?._tag_content_details
                                                        ?.actor
                                                    ),
                                                  },
                                                },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        {...GlobalStyles.LinkStyles(theme)[
                                          'Link'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.LinkStyles(theme)[
                                              'Link'
                                            ].style,
                                            {
                                              color:
                                                palettes.App['Custom Color_2'],
                                              fontFamily:
                                                'SpaceGrotesk_400Regular',
                                              fontSize: 18,
                                              marginTop: 8,
                                              textDecorationLine: 'underline',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        title={`${fetchData?._tag_content_details?.actor}`}
                                      />
                                    </TourGuide.WrapperZone>
                                  </Utils.CustomCodeErrorBoundary>
                                </View>
                              </View>
                            </View>
                          </View>
                          {/* Actions */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'space-between',
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                height: 60,
                                justifyContent: 'space-evenly',
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Three Buttons */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 3 },
                                dimensions.width
                              )}
                            >
                              {/* CopilotWrapper 5 */}
                              <Utils.CustomCodeErrorBoundary>
                                <TourGuide.WrapperZone
                                  text={`Click these icons to like, comment on, and share
tags`}
                                  zone={5}
                                  borderRadius={6}
                                  maskOffset={10}
                                  tooltipBottomOffset={30}
                                  tourKey={tourKey}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* LikeButtonView */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignItems: 'center', flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* UnLikedTag */}
                                      <Checkbox
                                        onCheck={() => {
                                          try {
                                            updateGlobalLikes(
                                              Variables,
                                              setGlobalVariableValue,
                                              props.route?.params?.tagID ??
                                                1345,
                                              getLikesCount(
                                                Variables,
                                                props.route?.params?.tagID ??
                                                  1345
                                              ) + 1
                                            );
                                            updateGlobalIsLiked(
                                              Variables,
                                              setGlobalVariableValue,
                                              props.route?.params?.tagID ??
                                                1345,
                                              true
                                            );
                                            trackAction(
                                              Variables,
                                              segment,
                                              'Tag Liked',
                                              formatTrackData(fetchData?.id)
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        onPress={newUnLikedTagValue => {
                                          const handler = async () => {
                                            try {
                                              setIsLiking(true);
                                              (
                                                await NewXANOAPIApi.toggleLikePOST(
                                                  Constants,
                                                  {
                                                    tag_id:
                                                      props.route?.params
                                                        ?.tagID ?? 1345,
                                                  }
                                                )
                                              )?.json;
                                              setIsLiking(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        onUncheck={() => {
                                          try {
                                            updateGlobalLikes(
                                              Variables,
                                              setGlobalVariableValue,
                                              props.route?.params?.tagID ??
                                                1345,
                                              getLikesCount(
                                                Variables,
                                                props.route?.params?.tagID ??
                                                  1345
                                              ) - 1
                                            );
                                            updateGlobalIsLiked(
                                              Variables,
                                              setGlobalVariableValue,
                                              props.route?.params?.tagID ??
                                                1345,
                                              false
                                            );
                                            trackAction(
                                              Variables,
                                              segment,
                                              'Tag Unliked',
                                              formatTrackData(fetchData?.id)
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        checkedIcon={'AntDesign/heart'}
                                        color={theme.colors.background.brand}
                                        size={32}
                                        status={isLikedByUser}
                                        uncheckedColor={
                                          theme.colors.background.brand
                                        }
                                        uncheckedIcon={'AntDesign/hearto'}
                                      />
                                      {/* LikesCount */}
                                      <>
                                        {!showLikeCount(
                                          getLikesCount(
                                            Variables,
                                            props.route?.params?.tagID ?? 1345
                                          )
                                        ) ? null : (
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily:
                                                  'SpaceGrotesk_400Regular',
                                                fontSize: 12,
                                                lineHeight: 36,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {getLikesCount(
                                              Variables,
                                              props.route?.params?.tagID ?? 1345
                                            )}
                                          </Text>
                                        )}
                                      </>
                                      <>
                                        {!isLiking ? null : (
                                          <ActivityIndicator
                                            animating={true}
                                            hidesWhenStopped={true}
                                            size={'small'}
                                            {...GlobalStyles.ActivityIndicatorStyles(
                                              theme
                                            )['Activity Indicator'].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ActivityIndicatorStyles(
                                                theme
                                              )['Activity Indicator'].style,
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                    {/* CommentButtonView */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignItems: 'center', flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Comments */}
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'BottomTabNavigator',
                                              {
                                                screen: 'HomeStackNavgiator',
                                                params: {
                                                  screen:
                                                    'TagCommentPageScreen',
                                                  params: {
                                                    ClickedTagID:
                                                      props.route?.params
                                                        ?.tagID ?? 1345,
                                                  },
                                                },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['Custom Color_2']}
                                        icon={
                                          'MaterialCommunityIcons/message-processing-outline'
                                        }
                                        size={36}
                                      />
                                      {/* CommentsCount */}
                                      <>
                                        {!(commentsCount > 0) ? null : (
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily:
                                                  'SpaceGrotesk_400Regular',
                                                fontSize: 12,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {commentsCount}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                    {/* ShareButtonView */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignItems: 'center', flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Shares */}
                                      <IconButton
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              if (Platform.OS === 'web') {
                                                setShowDownloadModal(true);
                                              }
                                              if (Platform.OS === 'web') {
                                                return;
                                              }
                                              await openShareUtil(`Check out what I found on Montage 

https://searchmontage.com/tag/${props.route?.params?.tagID ?? 1345}`);
                                              setIsSharing(true);
                                              (
                                                await TagsAPIApi.shareTagPOST(
                                                  Constants,
                                                  {
                                                    tags_id:
                                                      props.route?.params
                                                        ?.tagID ?? 1345,
                                                  }
                                                )
                                              )?.json;
                                              setIsSharing(false);
                                              updateGlobalShares(
                                                Variables,
                                                setGlobalVariableValue,
                                                props.route?.params?.tagID ??
                                                  1345,
                                                getSharesCount(
                                                  Variables,
                                                  props.route?.params?.tagID ??
                                                    1345
                                                ) + 1
                                              );
                                              trackAction(
                                                Variables,
                                                segment,
                                                'Tag Shared',
                                                fetchData?.id?.format
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        color={palettes.App['Custom Color_2']}
                                        icon={'MaterialCommunityIcons/share'}
                                        size={36}
                                      />
                                      {/* ShareText */}
                                      <>
                                        {!showShareCount(
                                          getSharesCount(
                                            Variables,
                                            props.route?.params?.tagID ?? 1345
                                          )
                                        ) ? null : (
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily:
                                                  'SpaceGrotesk_400Regular',
                                                fontSize: 12,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {getSharesCount(
                                              Variables,
                                              props.route?.params?.tagID ?? 1345
                                            )}
                                          </Text>
                                        )}
                                      </>
                                      <>
                                        {!isSharing ? null : (
                                          <ActivityIndicator
                                            animating={true}
                                            hidesWhenStopped={true}
                                            size={'small'}
                                            {...GlobalStyles.ActivityIndicatorStyles(
                                              theme
                                            )['Activity Indicator'].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ActivityIndicatorStyles(
                                                theme
                                              )['Activity Indicator'].style,
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  </View>
                                </TourGuide.WrapperZone>
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                            {/* OneButton */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              {/* ProfileView */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center', flex: 1 },
                                  dimensions.width
                                )}
                              >
                                {/* CopilotWrapper 6 */}
                                <Utils.CustomCodeErrorBoundary>
                                  <TourGuide.WrapperZone
                                    text={
                                      'Head to this tagger’s profile to see more of their styles'
                                    }
                                    zone={6}
                                    shape="circle"
                                    tourKey={tourKey}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'BottomTabNavigator',
                                            {
                                              screen: 'HomeStackNavgiator',
                                              params: {
                                                screen: 'TaggerProfileScreen',
                                                params: {
                                                  initialTabIndex: 0,
                                                  taggerId:
                                                    fetchData?.tagger_id,
                                                },
                                              },
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      activeOpacity={0.8}
                                      disabledOpacity={0.8}
                                    >
                                      <>
                                        {fetchData?._tagger_details
                                          ?.profile_photo?.url ? null : (
                                          <Icon
                                            color={
                                              theme.colors.background.brand
                                            }
                                            name={'FontAwesome/user-circle'}
                                            size={36}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!fetchData?._tagger_details
                                          ?.profile_photo?.url ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${fetchData?._tagger_details?.profile_photo?.url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderColor:
                                                  theme.colors.background.brand,
                                                borderRadius: 25,
                                                borderWidth: 1,
                                                height: 36,
                                                overflow: 'hidden',
                                                width: 36,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </Touchable>
                                  </TourGuide.WrapperZone>
                                </Utils.CustomCodeErrorBoundary>
                              </View>
                            </View>
                          </View>
                        </View>
                        {/* BottomScreen */}
                        <View
                          style={StyleSheet.applyWidth(
                            { height: dimensions.height, marginBottom: 25 },
                            dimensions.width
                          )}
                        >
                          {/* List */}
                          <Utils.CustomCodeErrorBoundary>
                            <TagLists.List
                              endPoint="get_related_tags"
                              theme={props.theme}
                              navigation={props.navigation}
                              tags_id={props.route?.params?.tagID ?? 632}
                              promotionCriteria={{}}
                              showDownloadModal={() => {
                                setShowFeatureMessage(true);
                                setShowDownloadModal(true);
                              }}
                            />
                          </Utils.CustomCodeErrorBoundary>
                        </View>
                      </View>
                    </>
                  );
                }}
              </TagsAPIApi.FetchGetTagDetailsGET>
            )}
          </>
          {/* ClickedTagNoAuth */}
          <>
            {Constants['USER']?.id ? null : (
              <TagsAPIApi.FetchGetTagDetailsNoAuthGET
                handlers={{
                  onData: clickedTagNoAuthData => {
                    try {
                      trackAction(
                        Variables,
                        segment,
                        'TagView',
                        clickedTagNoAuthData
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                tags_id={props.route?.params?.tagID ?? 1345}
              >
                {({ loading, error, data, refetchGetTagDetailsNoAuth }) => {
                  const clickedTagNoAuthData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* ResponsiveWrapper */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)[
                          'ResponsiveWrapperView'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)[
                              'ResponsiveWrapperView'
                            ].style,
                            {
                              marginTop: 25,
                              paddingLeft: 11,
                              paddingRight: 11,
                              width: dimensions.width,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* UpperScreen */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: [
                                { minWidth: Breakpoints.Mobile, value: '100%' },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: getUpperScreenHeight(),
                                },
                              ],
                              marginBottom: 12,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1, justifyContent: 'flex-end' },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 3,
                                  borderColor: palettes.App['Border Color'],
                                  flex: 1,
                                  justifyContent: 'flex-end',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!clickedTagNoAuthData?.id ? null : (
                                  <Link
                                    accessible={true}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await WebBrowser.openBrowserAsync(
                                            `${clickedTagNoAuthData?._tag_retail_details?.product_link}`
                                          );
                                          trackAction(
                                            Variables,
                                            segment,
                                            'Product Link Clicked',
                                            formatTrackProductLinkData(
                                              clickedTagNoAuthData
                                                ?._tag_retail_details
                                                ?.product_link,
                                              clickedTagNoAuthData
                                                ?._tag_retail_details
                                                ?.product_name
                                            )
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    {...GlobalStyles.LinkStyles(theme)['Link']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)['Link']
                                          .style,
                                        {
                                          color: palettes.App['Custom Color_2'],
                                          fontFamily: 'SpaceGrotesk_400Regular',
                                          fontSize: {
                                            minWidth: Breakpoints.Tablet,
                                            value: 18,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Click for Product Link'}
                                  />
                                )}
                              </>
                              <Touchable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await WebBrowser.openBrowserAsync(
                                        `${clickedTagNoAuthData?._tag_retail_details?.product_link}`
                                      );
                                      trackAction(
                                        Variables,
                                        segment,
                                        'Tag Image Clicked',
                                        formatTrackProductLinkData(
                                          clickedTagNoAuthData
                                            ?._tag_retail_details?.product_link,
                                          clickedTagNoAuthData
                                            ?._tag_retail_details?.product_name
                                        )
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                activeOpacity={0.8}
                                disabledOpacity={0.8}
                                style={StyleSheet.applyWidth(
                                  { height: '90%', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flex: 1,
                                      justifyContent: 'flex-end',
                                      maxWidth: 1024,
                                      width: dimensions.width,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    {...GlobalStyles.ImageStyles(theme)[
                                      'ResponsiveProductImage'
                                    ].props}
                                    resizeMode={'contain'}
                                    source={{
                                      uri: `${
                                        (
                                          clickedTagNoAuthData?._tag_photos &&
                                          (clickedTagNoAuthData?._tag_photos)[0]
                                        )?.tag_photo?.url
                                      }`,
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'ResponsiveProductImage'
                                        ].style,
                                        {
                                          height: '100%',
                                          maxHeight: (
                                            clickedTagNoAuthData?._tag_photos &&
                                            (clickedTagNoAuthData
                                              ?._tag_photos)[0]
                                          )?.tag_photo?.meta?.height,
                                          maxWidth: (
                                            clickedTagNoAuthData?._tag_photos &&
                                            (clickedTagNoAuthData
                                              ?._tag_photos)[0]
                                          )?.tag_photo?.meta?.width,
                                          width: '100%',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </Touchable>
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginTop: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 25,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 30 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* TagText */}
                              <View>
                                {/* CopilotWrapper */}
                                <Utils.CustomCodeErrorBoundary>
                                  <TourGuide.WrapperZone
                                    text={'Click here for the product link'}
                                    zone={1}
                                    borderRadius={6}
                                    maskOffset={6}
                                    tourKey={tourKey}
                                  >
                                    {/* Product Name Pressable */}
                                    <Pressable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            await WebBrowser.openBrowserAsync(
                                              `${clickedTagNoAuthData?._tag_retail_details?.product_link}`
                                            );
                                            trackAction(
                                              Variables,
                                              segment,
                                              'Product Link Click',
                                              formatTrackProductLinkData(
                                                clickedTagNoAuthData
                                                  ?._tag_retail_details
                                                  ?.product_link,
                                                clickedTagNoAuthData
                                                  ?._tag_retail_details
                                                  ?.product_name
                                              )
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: palettes.App.White,
                                                fontSize: 22,
                                                textDecorationLine: 'underline',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            clickedTagNoAuthData
                                              ?._tag_retail_details
                                              ?.product_name
                                          }
                                        </Text>
                                        <Icon
                                          color={palettes.App.White}
                                          name={'Entypo/price-tag'}
                                          size={30}
                                          style={StyleSheet.applyWidth(
                                            { marginLeft: 8 },
                                            dimensions.width
                                          )}
                                        />
                                      </View>
                                    </Pressable>
                                  </TourGuide.WrapperZone>
                                </Utils.CustomCodeErrorBoundary>
                                {/* CopilotWrapper 2 */}
                                <Utils.CustomCodeErrorBoundary>
                                  <TourGuide.WrapperZone
                                    text={
                                      'Click here to see more tags from this brand'
                                    }
                                    zone={2}
                                    borderRadius={6}
                                    maskOffset={6}
                                    tourKey={tourKey}
                                  >
                                    {/* CreativeSourceLink */}
                                    <Link
                                      accessible={true}
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'BottomTabNavigator',
                                            {
                                              screen: 'HomeStackNavgiator',
                                              params: {
                                                screen: 'HomepageScreen',
                                                params: {
                                                  pid: null,
                                                  query: null,
                                                  initialFacets: facetCreator(
                                                    '_tag_retail_details.brand',
                                                    clickedTagNoAuthData
                                                      ?._tag_retail_details
                                                      ?.brand
                                                  ),
                                                },
                                              },
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      {...GlobalStyles.LinkStyles(theme)['Link']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.LinkStyles(theme)['Link']
                                            .style,
                                          {
                                            color:
                                              palettes.App['Custom Color_2'],
                                            fontFamily:
                                              'SpaceGrotesk_400Regular',
                                            fontSize: 18,
                                            marginTop: 8,
                                            textDecorationLine: 'underline',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={`${clickedTagNoAuthData?._tag_retail_details?.brand}`}
                                    />
                                  </TourGuide.WrapperZone>
                                </Utils.CustomCodeErrorBoundary>
                                {/* CopilotWrapper 3 */}
                                <Utils.CustomCodeErrorBoundary>
                                  <TourGuide.WrapperZone
                                    text={
                                      'Click here to see more tags from this show'
                                    }
                                    zone={3}
                                    borderRadius={6}
                                    maskOffset={6}
                                    tourKey={tourKey}
                                  >
                                    {/* Series and Episode */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.Brand['Strong Inverse'],
                                          fontFamily: 'SpaceGrotesk_400Regular',
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 22,
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 18,
                                            },
                                          ],
                                          marginTop: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* SeriesLink */}
                                      <Link
                                        accessible={true}
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'BottomTabNavigator',
                                              {
                                                screen: 'HomeStackNavgiator',
                                                params: {
                                                  screen: 'HomepageScreen',
                                                  params: {
                                                    pid: null,
                                                    query: null,
                                                    initialFacets: facetCreator(
                                                      '_tag_content_details.series_name',
                                                      clickedTagNoAuthData
                                                        ?._tag_content_details
                                                        ?.series_name
                                                    ),
                                                  },
                                                },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        {...GlobalStyles.LinkStyles(theme)[
                                          'Link'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.LinkStyles(theme)[
                                              'Link'
                                            ].style,
                                            {
                                              color:
                                                palettes.App['Custom Color_2'],
                                              textDecorationLine: 'underline',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        title={`${clickedTagNoAuthData?._tag_content_details?.series_name} `}
                                      />
                                      {/* EpisodeLink */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {' '}
                                        {
                                          clickedTagNoAuthData
                                            ?._tag_content_details?.episode
                                        }
                                      </Text>
                                      {null}
                                    </Text>
                                  </TourGuide.WrapperZone>
                                </Utils.CustomCodeErrorBoundary>
                                {/* CopilotWrapper 4 */}
                                <Utils.CustomCodeErrorBoundary>
                                  <TourGuide.WrapperZone
                                    text={
                                      'Click here to see more tags from this actor'
                                    }
                                    zone={4}
                                    borderRadius={6}
                                    maskOffset={6}
                                    tourKey={tourKey}
                                  >
                                    {/* ActorNameLink */}
                                    <Link
                                      accessible={true}
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'BottomTabNavigator',
                                            {
                                              screen: 'HomeStackNavgiator',
                                              params: {
                                                screen: 'HomepageScreen',
                                                params: {
                                                  pid: null,
                                                  query: null,
                                                  initialFacets: facetCreator(
                                                    '_tag_content_details.actor',
                                                    clickedTagNoAuthData
                                                      ?._tag_content_details
                                                      ?.actor
                                                  ),
                                                },
                                              },
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      {...GlobalStyles.LinkStyles(theme)['Link']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.LinkStyles(theme)['Link']
                                            .style,
                                          {
                                            color:
                                              palettes.App['Custom Color_2'],
                                            fontFamily:
                                              'SpaceGrotesk_400Regular',
                                            fontSize: 18,
                                            marginTop: 8,
                                            textDecorationLine: 'underline',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={`${clickedTagNoAuthData?._tag_content_details?.actor}`}
                                    />
                                  </TourGuide.WrapperZone>
                                </Utils.CustomCodeErrorBoundary>
                              </View>
                            </View>
                          </View>
                          {/* Actions */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'space-between',
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                height: 60,
                                justifyContent: 'space-evenly',
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Three Buttons */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 3 },
                                dimensions.width
                              )}
                            >
                              {/* CopilotWrapper 5 */}
                              <Utils.CustomCodeErrorBoundary>
                                <TourGuide.WrapperZone
                                  text={`Click these icons to like, comment on, and share
tags`}
                                  zone={5}
                                  borderRadius={6}
                                  maskOffset={10}
                                  tooltipBottomOffset={30}
                                  tourKey={tourKey}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* LikeButtonView */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignItems: 'center', flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            navigation.navigate('LoginScreen');
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={theme.colors.background.brand}
                                        icon={'Feather/heart'}
                                        size={36}
                                      />
                                      {/* LikesCount */}
                                      <>
                                        {!clickedTagNoAuthData?._likes_of_tags ? null : (
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily:
                                                  'SpaceGrotesk_400Regular',
                                                fontSize: 12,
                                                lineHeight: 36,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              clickedTagNoAuthData?._likes_of_tags
                                            }
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                    {/* CommentButtonView */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignItems: 'center', flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Comments */}
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            navigation.navigate('LoginScreen');
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['Custom Color_2']}
                                        icon={
                                          'MaterialCommunityIcons/message-processing-outline'
                                        }
                                        size={42}
                                      />
                                      {/* CommentsCount */}
                                      <>
                                        {!(
                                          clickedTagNoAuthData?._comments_of_tags >
                                          0
                                        ) ? null : (
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily:
                                                  'SpaceGrotesk_400Regular',
                                                fontSize: 12,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              clickedTagNoAuthData?._comments_of_tags
                                            }
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                    {/* ShareButtonView */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignItems: 'center', flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Shares */}
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            navigation.navigate('LoginScreen');
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['Custom Color_2']}
                                        icon={'MaterialCommunityIcons/share'}
                                        size={42}
                                      />
                                      {/* ShareText */}
                                      <>
                                        {!(
                                          clickedTagNoAuthData?._share_count > 0
                                        ) ? null : (
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily:
                                                  'SpaceGrotesk_400Regular',
                                                fontSize: 12,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {clickedTagNoAuthData?._share_count}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                </TourGuide.WrapperZone>
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                            {/* OneButton */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              {/* ProfileView */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center', flex: 1 },
                                  dimensions.width
                                )}
                              >
                                {/* CopilotWrapper 6 */}
                                <Utils.CustomCodeErrorBoundary>
                                  <TourGuide.WrapperZone
                                    text={
                                      'Head to this tagger’s profile to see more of their styles'
                                    }
                                    zone={6}
                                    shape="circle"
                                    tourKey={tourKey}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          navigation.navigate('LoginScreen');
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      activeOpacity={0.8}
                                      disabledOpacity={0.8}
                                    >
                                      <>
                                        {clickedTagNoAuthData?._tagger_details
                                          ?.profile_photo?.url ? null : (
                                          <Icon
                                            color={
                                              theme.colors.background.brand
                                            }
                                            name={'FontAwesome/user-circle'}
                                            size={42}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!clickedTagNoAuthData?._tagger_details
                                          ?.profile_photo?.url ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${clickedTagNoAuthData?._tagger_details?.profile_photo?.url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderColor:
                                                  theme.colors.background.brand,
                                                borderRadius: 25,
                                                borderWidth: 1,
                                                height: 42,
                                                overflow: 'hidden',
                                                width: 42,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </Touchable>
                                  </TourGuide.WrapperZone>
                                </Utils.CustomCodeErrorBoundary>
                              </View>
                            </View>
                          </View>
                        </View>
                        {/* BottomScreen */}
                        <View
                          style={StyleSheet.applyWidth(
                            { height: dimensions.height, marginBottom: 25 },
                            dimensions.width
                          )}
                        >
                          {/* List */}
                          <Utils.CustomCodeErrorBoundary>
                            <TagLists.List
                              endPoint="get_related_tags_no_auth"
                              theme={props.theme}
                              navigation={props.navigation}
                              tags_id={props.route?.params?.tagID}
                              promotionCriteria={{}}
                              showDownloadModal={() => {
                                setShowFeatureMessage(true);
                                setShowDownloadModal(true);
                              }}
                            />
                          </Utils.CustomCodeErrorBoundary>
                        </View>
                      </View>
                    </>
                  );
                }}
              </TagsAPIApi.FetchGetTagDetailsNoAuthGET>
            )}
          </>
        </View>
      </SimpleStyleScrollView>
      {/* Login modal */}
      <>
        {!Constants['showLoginModal'] ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['login modal'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['login modal'].style,
                { height: '100%', width: '100%' }
              ),
              dimensions.width
            )}
          >
            <BlurView
              intensity={50}
              tint={'default'}
              {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                  {
                    height: dimensions.height,
                    width: dimensions.width,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: '"rgba(255, 255, 255, 0.9)"',
                    borderColor: palettes.App['Border Color'],
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    opacity: 1,
                    paddingBottom: 40,
                    paddingTop: 40,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* ModalHeader */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: 16,
                      paddingBottom: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={Images.LogoPurpleFull}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 75, width: 225 }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* ModalBody */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexGrow: 1, flexShrink: 0, padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: 'SpaceGrotesk_600SemiBold',
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Welcome! \n\nCreate an account to personalize your experience and save tags.'
                    }
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginRight: 4 },
                        dimensions.width
                      )}
                    >
                      {/* SignupButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'API Request' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Navigate' action */
                            /* hidden 'Navigate' action */
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.branding.primary,
                            borderRadius: 8,
                            fontFamily: 'SpaceGrotesk_600SemiBold',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 16,
                            },
                            height: 50,
                            marginTop: 25,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                        title={'Signup'}
                      />
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 4 },
                        dimensions.width
                      )}
                    >
                      {/* LoginButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.navigate('LoginScreen');
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'API Request' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Navigate' action */
                            /* hidden 'Navigate' action */
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Outlined Button']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Outlined Button']
                              .style,
                            {
                              fontFamily: 'SpaceGrotesk_700Bold',
                              fontSize: {
                                minWidth: Breakpoints.Desktop,
                                value: 16,
                              },
                              height: 50,
                              marginTop: 25,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Log in'}
                      />
                    </View>
                  </View>
                  {/* ErrorMessage */}
                  <>
                    {!errorMessage ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.background.danger,
                            fontFamily: 'SpaceGrotesk_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 16,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {errorMessage}
                      </Text>
                    )}
                  </>
                  {/* Don't have an account  */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 30,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'showLoginModal',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { marginLeft: 8 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['Custom #5f5a53'],
                            fontFamily: 'SpaceGrotesk_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Desktop, value: 16 },
                              { minWidth: Breakpoints.Mobile, value: 12 },
                            ],
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Skip for now'}
                      </Text>
                    </Touchable>
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 12 },
                            { minWidth: Breakpoints.Desktop, value: 14 },
                          ],
                          marginTop: 12,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'-- or --'}
                  </Text>
                  {/* SoicalLogins */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SoicalLogins 2'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['SoicalLogins 2'].style,
                        { marginTop: 20 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* GoogleView */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      {/* GoogleAuthWrapper */}
                      <Utils.CustomCodeErrorBoundary>
                        <AuthComponents.GoogleLogin
                          navigation={props.navigation}
                          theme={props.theme}
                          setErrorMessage={setErrorMessage}
                          setIsLoading={setIsLoginWithGoogle}
                          isLoading={isLoginWithGoogle}
                          onLogin={() =>
                            setGlobalVariableValue({
                              key: 'showLoginModal',
                              value: false,
                            })
                          }
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                alignSelf: 'auto',
                                backgroundColor: theme.colors.background.brand,
                                borderColor: palettes.App['Border Color'],
                                borderRadius: 12,
                                borderWidth: 1,
                                flex: 1,
                                flexDirection: 'row',
                                height: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 60,
                                },
                                justifyContent: 'center',
                                maxHeight: 50,
                                paddingBottom: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <Image
                              resizeMode={'cover'}
                              {...GlobalStyles.ImageStyles(theme)['Image']
                                .props}
                              source={Images.Google}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image']
                                    .style,
                                  { height: 22, width: 22 }
                                ),
                                dimensions.width
                              )}
                            />
                            <>
                              {!isLoginWithGoogle ? null : (
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  {...GlobalStyles.ActivityIndicatorStyles(
                                    theme
                                  )['Activity Indicator'].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ActivityIndicatorStyles(theme)[
                                      'Activity Indicator'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </View>
                        </AuthComponents.GoogleLogin>
                      </Utils.CustomCodeErrorBoundary>
                    </View>
                  </View>
                </View>
              </View>
            </BlurView>
          </View>
        )}
      </>
      {/* Download modal */}
      <>
        {!showDownloadModal ? null : (
         <View
         {...GlobalStyles.ViewStyles(theme)['LoginModalView'].props}
         style={StyleSheet.applyWidth(
           StyleSheet.compose(
             GlobalStyles.ViewStyles(theme)['LoginModalView'].style,
             { height: dimensions.height, width: dimensions.width }
           ),
           dimensions.width
         )}
       >
         <BlurView
           intensity={50}
           tint={'default'}
           {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
           style={StyleSheet.applyWidth(
             StyleSheet.compose(
               GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
               {
                 height: dimensions.height,
                 width: dimensions.width,
                 alignItems: 'center',
                 justifyContent: 'center',
               }
             ),
             dimensions.width
           )}
         >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    borderColor: palettes.App['Border Color'],
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    opacity: 1,
                    paddingBottom: 40,
                    paddingTop: 40,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* CloseButton */}
                <IconButton
                  onPress={() => {
                    try {
                      setShowDownloadModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'AntDesign/closecircleo'}
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 8, top: 8 },
                    dimensions.width
                  )}
                />
                {/* ModalHeader */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: 16,
                      paddingBottom: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={Images.LogoPurpleFull}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 75, width: 225 }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* ModalBody */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexGrow: 1, flexShrink: 0, padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* DownloadText */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                          .style,
                        {
                          color: theme.colors.text.strong,
                          marginBottom: 40,
                          marginTop: 40,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Download the Montage app to activate this feature'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* AppStoreButton */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://apps.apple.com/us/app/montage-search-tv-style/id6445878135'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 5,
                            flex: 1,
                            margin: 5,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={Images.AppleAppStoreButton}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { height: 40, width: 130 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                    {/* PlayStoreButton */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://play.google.com/store/apps/details?id=com.searchmontage.app&hl=en-US&ah=nQPwrvIrI6Uex14KJwiIkePiWGQ'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 5,
                            flex: 1,
                            margin: 5,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={Images.PlayStoreButton}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { height: 40, width: 130 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              </View>
            </BlurView>
          </View>
        )}
      </>
      {/* Tour Modal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        transparent={true}
        visible={Constants['SHOW_TAGPAGE_TOUR']}
      >
        <BlurView
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
            {
              height: dimensions.height,
              width: dimensions.width,
              alignItems: 'center',
              justifyContent: 'center',
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App['Border Color'],
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                opacity: 1,
                paddingBottom: 40,
                paddingTop: 40,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* CloseButton */}
            <IconButton
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'SHOW_TAGPAGE_TOUR',
                    value: false,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/closecircleo'}
              style={StyleSheet.applyWidth(
                { position: 'absolute', right: 8, top: 8 },
                dimensions.width
              )}
            />
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 16,
                  paddingBottom: 4,
                },
                dimensions.width
              )}
            >
              {/* DownloadText */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['ResponsiveNameText'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['ResponsiveNameText'].style,
                    {
                      color: theme.colors.text.strong,
                      marginBottom: 12,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Welcome to'}
              </Text>
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={Images.LogoPurpleFull}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 75, width: 225 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              {/* DownloadText */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['ResponsiveNameText'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['ResponsiveNameText'].style,
                    {
                      color: theme.colors.text.strong,
                      marginBottom: 40,
                      marginTop: 40,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Would you like a tour of the app?'}
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'SHOW_TAGPAGE_TOUR',
                        value: false,
                      });
                      setGlobalVariableValue({
                        key: 'SHOW_TOUR',
                        value: false,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderColor: palettes.App['Custom #5f5a53'],
                        borderWidth: 1,
                        color: palettes.App['Custom #5f5a53'],
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'No thank you'}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* ConfirmButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      startTour(setGlobalVariableValue);
                      setGlobalVariableValue({
                        key: 'SHOW_TAGPAGE_TOUR',
                        value: false,
                      });
                      setGlobalVariableValue({
                        key: 'SHOW_TOUR',
                        value: true,
                      });
                      undefined;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: theme.colors.branding.primary,
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Yes!'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(ClickedTagPageScreen);