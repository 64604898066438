import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import { Touchable } from '@draftbit/ui';
import * as Google from 'expo-auth-session/providers/google';
import * as WebBrowser from 'expo-web-browser';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as AppleAuthentication from 'expo-apple-authentication';
import jwtDecode from 'jwt-decode';
import { useAnalytics } from '@segment/analytics-react-native';

WebBrowser.maybeCompleteAuthSession();

export function GoogleLogin({
  children,
  navigation,
  setIsLoading,
  setErrorMessage,
  isLoading,
  onLogin,
}) {
  const [accessToken, setAccessToken] = useState(null);
  const segment = useAnalytics();

  const variables = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const redirectUri =
    'https://searchmontage.com/redirect?deepLinkTo=montage-app://LoginScreen';

  const [request, response, promptAsync] = Google.useAuthRequest(
    {
      iosClientId: variables.GOOGLE_IOS_CLIENT_ID,
      androidClientId: variables.GOOGLE_ANDROID_CLIENT_ID,
      expoClientId: variables.GOOGLE_IOS_CLIENT_ID,
      webClientId: variables.GOOGLE_WEB_CLIENT_ID,
    },
    {
      scheme: 'montage-app://',
      path: 'LoginScreen',
    }
  );

  useEffect(() => {
    // console.log('response', response);
    if (response) {
      if (response.type === 'success') {
        setAccessToken(response.authentication.accessToken);
      } else {
        setErrorMessage('Error: Could not receive access token');
      }
    }
  }, [response, setErrorMessage]);

  useEffect(() => {
    if (accessToken) {
      const handler = async () => {
        try {
          setIsLoading(true);
          const loginResponse = await fetch(
            `https://x8x4-ck6z-yoac.n7.xano.io/api:U0aE1wpF/oauth/google?token=${accessToken}`
          );
          const data = await loginResponse.json();
          const token = data?.auth_token;
          const errorMessage = data?.message;
          setErrorMessage(errorMessage);
          setIsLoading(false);
          setGlobalVariableValue({
            key: 'AUTH_TOKEN',
            value: 'Bearer ' + token,
          });
          setGlobalVariableValue({
            key: 'USER',
            value: data?.user,
          });
          segment.identify(data?.user?.email, {
            platform: Platform.OS,
          });
          // console.log('65', data?.user);

          if (
            !data?.user?._profile_of_user?.name ||
            !data?.user?._profile_of_user?.handle
          ) {
            navigation.navigate('SignUpScreen', { completeOnBoarding: true });
            onLogin && onLogin();
          } else {
            navigation.navigate('BottomTabNavigator', {
              screen: 'DiscoverScreen',
            });
            onLogin && onLogin();
          }
        } catch (err) {
          console.error(err);
          setErrorMessage(err);
        }
      };
      handler();
    }
  }, [accessToken]);

  return (
    <Touchable
      style={{ width: '100%', minHeight: 45 }}
      disabled={isLoading}
      onPress={() => {
        try {
          promptAsync({ useProxy: false, showInRecents: true });
        } catch (err) {
          setErrorMessage(err);
        }
      }}
    >
      {children}
    </Touchable>
  );
}

export function AppleWrapper({
  children,
  setIsLoading,
  setErrorMessage,
  isLoading,
  navigation,
  onLogin,
}) {
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [appleAuthAvailable, setAppleAuthAvailable] = useState(false);
  const segment = useAnalytics();

  useEffect(() => {
    const checkAvailable = async () => {
      const isAvailable = await AppleAuthentication.isAvailableAsync();
      setAppleAuthAvailable(isAvailable);
    };
    checkAvailable();
  }, []);

  const login = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      const decoded = jwtDecode(credential?.identityToken);

      setTimeout(async () => {
        //  console.log('calling function');
        setIsLoading(true);
        signupWithSocial(
          {
            email: decoded.email,
            user_id: credential?.user,
            first_name: credential?.fullName.givenName || '',
            last_name: credential?.fullName.lastName || '',
          },
          navigation,
          setGlobalVariableValue,
          setErrorMessage,
          onLogin
        );

        segment.identify(decoded.email, {
          platform: Platform.OS,
        });

        setIsLoading(false);
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  if (appleAuthAvailable) {
    return (
      <Touchable
        onPress={login}
        disabled={!appleAuthAvailable || isLoading}
        style={{ width: '100%', minHeight: 45 }}
      >
        {children}
      </Touchable>
    );
  }
  return null;
}

const signupWithSocial = async (
  data,
  navigation,
  setGlobal,
  setErrorMessage,
  onLogin
) => {
  const { email, user_id, first_name, last_name } = data;
  //  console.log('data', email, user_id, first_name, last_name );

  const signupResponse = await fetch(
    `https://x8x4-ck6z-yoac.n7.xano.io/api:lS4lX9Ai/oauth/apple`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        user_id,
        first_name,
        last_name,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const responseData = await signupResponse.json();
  const token = responseData?.auth_token;
  // console.log('196', responseData);

  if (!responseData?.success) {
    setErrorMessage(responseData.message); //data?.message)
  }

  if (token) {
    setGlobal({
      key: 'AUTH_TOKEN',
      value: token,
    });
  }

  if (responseData?.user?._profile_of_user?.handle) {
    setGlobal({
      key: 'USER',
      value: responseData?.user,
    });

    navigation.navigate('BottomTabNavigator', {
      screen: 'DiscoverScreen',
    });
    onLogin && onLogin();
  } else {
    setGlobal({
      key: 'USER',
      value: {
        _profile_of_user: {
          email,
          name: `${first_name}${last_name ? ` ${last_name}` : ''}`,
        },
      },
    });
    navigation.navigate('SignUpScreen', { completeOnBoarding: true });
    onLogin && onLogin();
  }
};
