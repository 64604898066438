export default {
  App: {
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    custom_rgb0_0_0: 'rgb(0, 0, 0)',
    'Custom Color': 'rgb(0, 0, 0)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    'Custom Color_2': 'rgb(255, 255, 255)',
    backgroundPurple: 'rgb(4, 5, 23)',
    BackgroundPurple: 'rgb(4, 5, 23)',
    custom_rgb243_102_102: 'rgb(243, 102, 102)',
    'Custom Color_11': 'rgb(243, 102, 102)',
    socialOrange: 'rgb(255, 135, 67)',
    'Social Orange': 'rgb(255, 135, 67)',
    custom_rgb172_172_172: 'rgb(172, 172, 172)',
    'Custom #acacac': 'rgb(172, 172, 172)',
    bGGray: 'rgba(225, 218, 218, 0.15)',
    'BG Gray': 'rgba(225, 218, 218, 0.15)',
    custom_rgb95_90_83: 'rgb(95, 90, 83)',
    'Custom #5f5a53': 'rgb(95, 90, 83)',
    custom_rgb88_115_238: 'rgb(88, 115, 238)',
    'Custom Color_10': 'rgb(88, 115, 238)',
    white: 'rgb(255, 255, 255)',
    White: 'rgb(255, 255, 255)',
    custom_rgb6_48_63: 'rgb(6, 48, 63)',
    'Custom #06303f': 'rgb(6, 48, 63)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    viewBG: 'rgba(152, 153, 156, 0.25)',
    ViewBG: 'rgba(152, 153, 156, 0.25)',
    appGreen: 'rgb(0, 204, 154)',
    'App Green': 'rgb(0, 204, 154)',
    borderColor: 'rgb(187, 195, 203)',
    'Border Color': 'rgb(187, 195, 203)',
    textPlaceholder: 'rgb(136, 144, 153)',
    TextPlaceholder: 'rgb(136, 144, 153)',
    cardBorder: 'rgb(74, 74, 101)',
    CardBorder: 'rgb(74, 74, 101)',
  },
  Brand: {
    medium: 'rgba(70, 78, 88, 1)',
    Medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    divider: 'rgba(234, 237, 242, 1)',
    Divider: 'rgba(234, 237, 242, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    error: 'rgba(255, 69, 100, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    background: 'rgba(251, 252, 253, 1)',
    Background: 'rgba(251, 252, 253, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    light: 'rgba(165, 173, 183, 1)',
    Light: 'rgba(165, 173, 183, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    strong: 'rgb(20, 19, 43)',
    Strong: 'rgb(20, 19, 43)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    secondary: 'rgba(59, 201, 234, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    surface: 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
    primary: 'rgb(154, 80, 255)',
    Primary: 'rgb(154, 80, 255)',
  },
};
