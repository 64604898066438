import React from 'react';

const showTour = (Variables, setGlobalVariableValue, screen) => {
  if (screen === 'Homepage') {
    setGlobalVariableValue({
      key: 'SHOW_HOMEPAGE_TOUR',
      value: true,
    });
  }

  if (screen === 'ClickedTagPage') {
    console.log('clickedtag page tour show');
    setGlobalVariableValue({
      key: 'SHOW_TAGPAGE_TOUR',
      value: true,
    });
  }

  setGlobalVariableValue({
    key: 'TOURS_TO_SHOW',
    value: Variables?.TOURS_TO_SHOW.filter(t => t !== screen),
  });
};

export default showTour;
