import React from 'react';

import { List as XanoList } from './XanoList';
import { List as AlgoliaList } from './AlgoliaList';

export const List = ({
  theme,
  navigation,
  endPoint = 'get_tags_noauth', // 'get_tags','get_tags_noauth', 'get_related_tags', 'get_related_tags_no_auth', 'user_tags', 'liked_tags',
  searchQuery = '',
  setSearchQuery,
  searchbarFocued,
  promotion_id,
  promotionCriteria,
  initialFacets,
  tags_id,
  id,
  refreshData,
  showDownloadModal,
}) => {
  const [forceShowAlgolia, setForceShowAlgolia] = React.useState(false);
  if (
    ['get_tags', 'get_tags_noauth'].includes(endPoint) &&
    (searchQuery ||
      promotion_id ||
      initialFacets.length > 0 ||
      forceShowAlgolia)
  ) {
    // console.log('rendering algolia list');
    return (
      <AlgoliaList
        theme={theme}
        navigation={navigation}
        endPoint={endPoint}
        searchQuery={searchQuery}
        initialFacets={initialFacets}
        promotion_id={promotion_id}
        promotionCriteria={promotionCriteria}
        setSearchQuery={setSearchQuery}
        searchbarFocued={searchbarFocued}
        tags_id={tags_id}
        id={id}
        refreshData={refreshData}
        showDownloadModal={showDownloadModal}
        forceShow={setForceShowAlgolia}
      />
    );
  } else {
    console.log('rendering xano list');

    return (
      <XanoList
        theme={theme}
        navigation={navigation}
        endPoint={endPoint}
        searchQuery={searchQuery}
        initialFacets={initialFacets}
        promotionCriteria={promotionCriteria}
        setSearchQuery={setSearchQuery}
        searchbarFocued={searchbarFocued}
        tags_id={tags_id}
        id={id}
        refreshData={refreshData}
        showDownloadModal={showDownloadModal}
      />
    );
  }
};
