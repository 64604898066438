import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as TagsAPIApi from '../apis/TagsAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AuthComponents from '../custom-files/AuthComponents';
import * as CustomCode from '../custom-files/CustomCode';
import * as Lib from '../custom-files/Lib';
import * as TagLists from '../custom-files/TagLists';
import * as TourGuide from '../custom-files/TourGuide';
import formatGoToHomepageParams from '../global-functions/formatGoToHomepageParams';
import initializeTours from '../global-functions/initializeTours';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import showTour from '../global-functions/showTour';
import subtract from '../global-functions/subtract';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const HomepageScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const urlDecode = str => {
    // console.log('urlDecode',str, decodeURIComponent(str.replace(/\+/g,' ')));
    if (Platform.OS === 'web' && str) {
      return decodeURIComponent(str?.replace(/\+/g, ' '));
    }
    return str;
  };
  const [emailValue, setEmailValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoginWithGoogle, setIsLoginWithGoogle] = React.useState(false);
  const [passwordValue, setPasswordValue] = React.useState('');
  const [searchBarValue, setSearchBarValue] = React.useState(
    urlDecode(props.route?.params?.query ?? '')
  );
  const [searchbarFocued, setSearchbarFocued] = React.useState(false);
  const [selectedFacets, setSelectedFacets] = React.useState(
    props.route?.params?.initialFacets ?? [
      '_tag_content_details.character:Sam',
      '_tag_content_details.network:Hulu',
    ]
  );
  const [selectedPromotionCriteria, setSelectedPromotionCriteria] =
    React.useState({});
  const [selectedPromotionId, setSelectedPromotionId] = React.useState(
    props.route?.params?.pid ?? ''
  );
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);
  const [showFeatureMessage, setShowFeatureMessage] = React.useState(true);
  const showGoBackButton = () => {
    const history = Variables?.HISTORY;

    if (history.length > 1 && !Constants['showLoginModal']) {
      const lastScreen = history[history.length - 2];
      return lastScreen.screenName !== 'WelcomeScreen';
    } else return false;

    // console.log(history[history.length -1])
    // return (history.length > 1) //Boolean(props.route?.params?.goBackToTagId)  //
  };

  const validEmail = () => {
    if (emailValue === '') return true;

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
  };

  const validForm = () => {
    if (!emailValue) {
      setErrorMessage('Please enter your email');
      return false;
    }
    if (!passwordValue) {
      setErrorMessage('Please enter your password');
      return false;
    }

    if (validEmail(emailValue) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const formatPromoClickData = item => {
    return {
      id: item.id,
      promo_name: item.promo_name,
    };
  };

  const goBack = () => {
    let history = Variables.HISTORY || [];

    //first remove the current screen from history
    history.pop();

    //then go back to the previous screen
    const previousScreen = history.pop();
    setGlobalVariableValue({
      key: 'HISTORY',
      value: history,
    });

    if (previousScreen) {
      props?.navigation?.navigate(
        previousScreen.screenName,
        previousScreen.params
      );
    }
  };

  const showModalIn = (setGlobalVariableValue, duration) => {
    if (Platform.OS === 'web') {
      const timeout = setTimeout(() => {
        setGlobalVariableValue({
          key: 'showLoginModal',
          value: true,
        });
      }, duration || 30000);

      setGlobalVariableValue({
        key: 'loginModalNotShowYet',
        value: false,
      });
    }
  };

  const startTour = () => {
    if (canStart) start();
  };

  const formatSearchQueryData = query => {
    return {
      query: (query || '').toLowerCase(),
    };
  };
  React.useEffect(() => {
    (async () => {
      if (props.route?.params?.pid) {
        let pid = isNaN(parseInt(props.route?.params?.pid))
          ? ''
          : parseInt(props.route?.params?.pid);
        // console.log('pid',pid )
        setSelectedPromotionId(pid);
        const criteria = await getCriteria(pid);

        if (criteria.length > 0) setSelectedPromotionCriteria(criteria[0]);
      } else {
        setSelectedPromotionId('');
        setSelectedPromotionCriteria({});
      }

      pushToHistory(
        Variables,
        setGlobalVariableValue,
        segment,
        'HomepageScreen',
        formatGoToHomepageParams(
          props.route?.params?.pid ?? '',
          props.route?.params?.query ?? '',
          props.route?.params?.initialFacets ?? ''
        )
      );
    })();
  }, [props.route?.params?.pid]);
  // console.log('props.route?.params',props.route?.params)

  React.useEffect(() => {
    // console.log('useEffect query', props.route?.params?.query)
    if (props.route?.params?.query)
      setSearchBarValue(urlDecode(props.route?.params?.query));
    else setSearchBarValue('');

    pushToHistory(
      Variables,
      setGlobalVariableValue,
      segment,
      'HomepageScreen',
      formatGoToHomepageParams(
        props.route?.params?.pid ?? '',
        props.route?.params?.query ?? '',
        props.route?.params?.initialFacets ?? ''
      )
    );
  }, [props.route?.params?.query]);

  React.useEffect(() => {
    console.log('useEffect initialFacets', props.route?.params?.initialFacets);

    const facet = props.route?.params?.initialFacets;
    if (!facet) {
      setSelectedFacets([]);
    } else if (typeof facet === 'string') {
      if (facet.includes('[')) {
        setSelectedFacets(JSON.parse(facet));
      } else {
        setSelectedFacets([facet]);
      }
    } else {
      setSelectedFacets(facet || []);
    }

    pushToHistory(
      Variables,
      setGlobalVariableValue,
      segment,
      'HomepageScreen',
      formatGoToHomepageParams(
        props.route?.params?.pid ?? '',
        props.route?.params?.query ?? '',
        props.route?.params?.initialFacets ?? ''
      )
    );
  }, [props.route?.params?.initialFacets]);

  const getCriteria = async promotions_id => {
    try {
      const promotion = (
        await TagsAPIApi.getPromotionGET(Constants, {
          promotions_id,
        })
      )?.json;
      return promotion.criteria;
    } catch (err) {
      console.error(err);
    }
  };

  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    stop, // a function  to stopping it
    eventEmitter, // an object for listening some events,
    tourKey,
  } = Lib.useTourGuideController();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      pushToHistory(
        Variables,
        setGlobalVariableValue,
        segment,
        'HomepageScreen',
        formatGoToHomepageParams(
          props.route?.params?.pid ?? '',
          props.route?.params?.query ?? '',
          props.route?.params?.initialFacets ?? [
            '_tag_content_details.character:Sam',
            '_tag_content_details.network:Hulu',
          ]
        )
      );
      if (!Constants['USER']?.id && Constants['loginModalNotShowYet']) {
        showModalIn(setGlobalVariableValue, 30000);
      }
      if (
        navigateToCompleteOnboarding(
          navigation,
          setGlobalVariableValue,
          Constants['USER']
        )
      ) {
        navigation.navigate('SignUpScreen', { completeOnBoarding: true });
      }
      if (Constants['USER']?.id) {
        setGlobalVariableValue({
          key: 'showLoginModal',
          value: false,
        });
      }
      if (
        (Constants['SHOW_TOUR'] === -1 || Constants['SHOW_TOUR'] === 1) &&
        !(Platform.OS === 'web')
      ) {
        if (Constants['TOURS_TO_SHOW'].includes('Homepage')) {
          showTour(Variables, setGlobalVariableValue, 'Homepage');
        } else {
        }
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: palettes.App.BackgroundPurple,
        },
        dimensions.width
      )}
    >
      <>
        {!showGoBackButton() ? null : (
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow']
              .props}
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow']
                .style,
              dimensions.width
            )}
          >
            {/* GoBackButton */}
            <>
              {!showGoBackButton() ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App['Custom Color_2']}
                      name={'AntDesign/leftcircle'}
                      size={40}
                    />
                  </View>
                </Touchable>
              )}
            </>
          </Surface>
        )}
      </>
      {/* ResponsiveWrapper */}
      <View
        {...GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].style,
            { width: dimensions.width }
          ),
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: 5,
              marginTop: 8,
              paddingLeft: 8,
              paddingRight: 8,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={Images['LogoWhiteFull']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 50, width: 150 }
                ),
                dimensions.width
              )}
            />
          </View>
        </View>
        {/* CopilotWrapper 2 */}
        <Utils.CustomCodeErrorBoundary>
          <TourGuide.WrapperZone
            text={'You can search for a TV show or character'}
            zone={2}
            borderRadius={6}
            tourKey={tourKey}
            maskOffset={6}
            tooltipBottomOffset={-40}
          >
            {/* Search */}
            <View
              {...GlobalStyles.ViewStyles(theme)['ResponsiveSearchBoxView']
                .props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['ResponsiveSearchBoxView']
                    .style,
                  { marginLeft: 4, marginRight: 4 }
                ),
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App['Custom Color_2']}
                name={'Feather/search'}
              />
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                {/* SearchBar */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  onBlur={() => {
                    try {
                      setSearchbarFocued(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeText={newSearchBarValue => {
                    try {
                      setSearchBarValue(newSearchBarValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newSearchBarValue => {
                    try {
                      navigation.navigate('BottomTabNavigator', {
                        screen: 'HomeStackNavgiator',
                        params: {
                          screen: 'HomepageScreen',
                          params: {
                            pid: selectedPromotionId,
                            query: newSearchBarValue,
                            initialFacets: selectedFacets,
                          },
                        },
                      });
                      if (newSearchBarValue !== '') {
                        trackAction(
                          Variables,
                          segment,
                          'Searched Query',
                          formatSearchQueryData(newSearchBarValue)
                        );
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onFocus={() => {
                    try {
                      setSearchbarFocued(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  allowFontScaling={false}
                  blurOnSubmit={true}
                  changeTextDelay={1000}
                  clearButtonMode={'never'}
                  disabled={Constants['isHomepageRefreshing']}
                  editable={true}
                  placeholder={'Search...'}
                  placeholderTextColor={palettes.App['Custom Color_2']}
                  spellcheck={true}
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 0,
                      color: palettes.App['Custom Color_2'],
                      fontFamily: 'SpaceGrotesk_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 15 },
                        { minWidth: Breakpoints.Desktop, value: 18 },
                      ],
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={searchBarValue}
                  webShowOutline={false}
                />
              </View>
              {/* Clear Button */}
              <>
                {!searchBarValue ? null : (
                  <IconButton
                    onPress={() => {
                      try {
                        setSearchBarValue('');
                        navigation.navigate('HomepageScreen', {
                          pid: selectedPromotionId,
                          query: '',
                          initialFacets: selectedFacets,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    color={palettes.App['Custom Color_2']}
                    disabled={Constants['isHomepageRefreshing']}
                    icon={'Ionicons/close-circle-outline'}
                    size={24}
                  />
                )}
              </>
            </View>
          </TourGuide.WrapperZone>
        </Utils.CustomCodeErrorBoundary>
        {/* Body */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: [
                { minWidth: Breakpoints.Desktop, value: 1 },
                { minWidth: Breakpoints.Mobile, value: 1 },
              ],
              overflow: 'hidden',
            },
            dimensions.width
          )}
        >
          {/* CopilotWrapper */}
          <Utils.CustomCodeErrorBoundary>
            <TourGuide.WrapperZone
              text={
                'Scroll the hompage to explore tags from your favorite shows, movies, and more'
              }
              zone={1}
              borderRadius={6}
              tourKey={tourKey}
              maskOffset={6}
              tooltipBottomOffset={-40}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: '100%',
                    maxHeight:
                      Platform.OS === 'web'
                        ? subtract(dimensions.height, 220)
                        : undefined,
                  },
                  dimensions.width
                )}
              >
                {/* Custom Mansonry List */}
                <>
                  {!Constants['USER']?.id ? null : (
                    <Utils.CustomCodeErrorBoundary>
                      <TagLists.List
                        endPoint="get_tags"
                        theme={props.theme}
                        navigation={props.navigation}
                        searchQuery={searchBarValue}
                        setSearchQuery={value => {
                          setSearchBarValue(value);
                        }}
                        initialFacets={selectedFacets}
                        searchbarFocued={searchbarFocued}
                        promotion_id={selectedPromotionId}
                        promotionCriteria={selectedPromotionCriteria}
                        showDownloadModal={() => {
                          setShowFeatureMessage(true);
                          setShowDownloadModal(true);
                        }}
                      />
                    </Utils.CustomCodeErrorBoundary>
                  )}
                </>
                {/* Custom Mansonry ListNoAuth */}
                <>
                  {Constants['USER']?.id ? null : (
                    <Utils.CustomCodeErrorBoundary>
                      <TagLists.List
                        endPoint="get_tags_noauth"
                        theme={props.theme}
                        navigation={props.navigation}
                        searchQuery={searchBarValue}
                        setSearchQuery={value => {
                          setSearchBarValue(value);
                        }}
                        initialFacets={selectedFacets}
                        searchbarFocued={searchbarFocued}
                        promotion_id={selectedPromotionId}
                        promotionCriteria={selectedPromotionCriteria}
                        showDownloadModal={() => {
                          setShowFeatureMessage(true);
                          setShowDownloadModal(true);
                        }}
                      />
                    </Utils.CustomCodeErrorBoundary>
                  )}
                </>
              </View>
            </TourGuide.WrapperZone>
          </Utils.CustomCodeErrorBoundary>
        </View>
        {/* CopilotWrapper 3 */}
        <Utils.CustomCodeErrorBoundary>
          <TourGuide.WrapperZone
            text={`Select a button to filter content by category, show, and more`}
            zone={3}
            borderRadius={6}
            maskOffset={6}
            tourKey={tourKey}
          >
            {/* Discover Buttons */}
            <View
              {...GlobalStyles.ViewStyles(theme)['Discover Buttons'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Discover Buttons'].style,
                  {
                    alignSelf: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Tablet, value: 'center' },
                    ],
                    marginTop: 9,
                    maxWidth: { minWidth: Breakpoints.Laptop, value: 1000 },
                  }
                ),
                dimensions.width
              )}
            >
              <TagsAPIApi.FetchGetDiscoverButtonsGET>
                {({ loading, error, data, refetchGetDiscoverButtons }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <SimpleStyleFlatList
                      data={fetchData}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'KiMhpbMQ'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Touchable
                            onPress={() => {
                              try {
                                trackAction(
                                  Variables,
                                  segment,
                                  'Promo Button Clicked',
                                  formatPromoClickData(listData)
                                );
                                if (
                                  parseInt(selectedPromotionId, 10) !==
                                  listData?.id
                                ) {
                                  setSelectedPromotionId(listData?.id);
                                }
                                if (
                                  parseInt(selectedPromotionId, 10) !==
                                  listData?.id
                                ) {
                                  setSelectedPromotionCriteria(
                                    listData?.criteria &&
                                      (listData?.criteria)[0]
                                  );
                                }
                                if (
                                  parseInt(selectedPromotionId, 10) ===
                                  listData?.id
                                ) {
                                  setSelectedPromotionId(0);
                                }
                                if (
                                  parseInt(selectedPromotionId, 10) ===
                                  listData?.id
                                ) {
                                  setSelectedPromotionCriteria({});
                                }
                                if (
                                  parseInt(selectedPromotionId, 10) !==
                                  listData?.id
                                ) {
                                  navigation.navigate('HomepageScreen', {
                                    pid: listData?.id,
                                    query: searchBarValue,
                                    initialFacets: selectedFacets,
                                  });
                                }
                                if (
                                  parseInt(selectedPromotionId, 10) ===
                                  listData?.id
                                ) {
                                  navigation.navigate('HomepageScreen', {
                                    pid: '',
                                    query: searchBarValue,
                                    initialFacets: props.route?.params
                                      ?.initialFacets ?? [
                                      '_tag_content_details.character:Sam',
                                      '_tag_content_details.network:Hulu',
                                    ],
                                  });
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            disabled={Constants['isHomepageRefreshing']}
                          >
                            {/* selectedView */}
                            <>
                              {!(
                                parseInt(selectedPromotionId, 10) ===
                                listData?.id
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        theme.colors.branding.primary,
                                      borderRadius: 24,
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      margin: 5,
                                      minHeight: 30,
                                      padding: 5,
                                      paddingLeft: 15,
                                      paddingRight: 15,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: palettes.App['Custom Color_2'],
                                          fontSize: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.promo_name}
                                  </Text>
                                </View>
                              )}
                            </>
                            <>
                              {!(
                                parseInt(selectedPromotionId, 10) !==
                                listData?.id
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor: palettes.App['BG Gray'],
                                      borderRadius: 24,
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      margin: 5,
                                      minHeight: 30,
                                      padding: 5,
                                      paddingLeft: 15,
                                      paddingRight: 15,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: palettes.App['Custom Color_2'],
                                          fontSize: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.promo_name}
                                  </Text>
                                </View>
                              )}
                            </>
                          </Touchable>
                        );
                      }}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    />
                  );
                }}
              </TagsAPIApi.FetchGetDiscoverButtonsGET>
            </View>
          </TourGuide.WrapperZone>
        </Utils.CustomCodeErrorBoundary>
        {/* CopilotZoneWrapper */}
        <Utils.CustomCodeErrorBoundary>
          <TourGuide.WrapperZonePosition
            zone={4}
            isTourGuide
            bottom={dimensions.height - 400}
            left={30}
            width={120}
            height={120}
            shape="circle"
            text={'Click on tags to see more details about this style'}
            tourKey={tourKey}
          />
        </Utils.CustomCodeErrorBoundary>
      </View>
      {/* FloatingGetAppButtonView */}
      <>
        {!(Platform.OS === 'web') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                borderRadius: 16,
                bottom: 70,
                left: 20,
                position: 'absolute',
                width: 150,
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  setShowDownloadModal(true);
                  setShowFeatureMessage(false);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.branding.primary,
                      borderRadius: 16,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: 12,
                    }
                  ),
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors.background.brand}
                  name={'Feather/download'}
                  style={StyleSheet.applyWidth(
                    { marginRight: 8 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'SpaceGrotesk_700Bold',
                        fontSize: 16,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Get the app'}
                </Text>
              </Surface>
            </Pressable>
          </View>
        )}
      </>
      {/* DownloadModal */}
      <>
        {!showDownloadModal ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['LoginModalView'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['LoginModalView'].style,
                { height: dimensions.height, width: dimensions.width }
              ),
              dimensions.width
            )}
          >
            <BlurView
              intensity={50}
              tint={'default'}
              {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                  {
                    height: dimensions.height,
                    width: dimensions.width,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    borderColor: palettes.App['Border Color'],
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    opacity: 1,
                    paddingBottom: 40,
                    paddingTop: 40,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* CloseButton */}
                <IconButton
                  onPress={() => {
                    try {
                      setShowDownloadModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'AntDesign/closecircleo'}
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 8, top: 8 },
                    dimensions.width
                  )}
                />
                {/* ModalHeader */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: 16,
                      paddingBottom: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={Images['LogoPurpleFull']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 75, width: 225 }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* ModalBody */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexGrow: 1, flexShrink: 0, padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* DownloadText */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['ResponsiveNameText']
                          .style,
                        {
                          color: theme.colors.text.strong,
                          marginBottom: 40,
                          marginTop: 40,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Download the Montage app'}
                    {/* DownloadText */}
                    <>
                      {!showFeatureMessage ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'ResponsiveNameText'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'ResponsiveNameText'
                              ].style,
                              {
                                color: theme.colors.text.strong,
                                marginBottom: 40,
                                marginTop: 40,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {' to activate this feature'}
                        </Text>
                      )}
                    </>
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* AppStoreButton */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://apps.apple.com/us/app/montage-search-tv-style/id6445878135'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 5,
                            flex: 1,
                            margin: 5,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={Images['AppleAppStoreButton']}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { height: 40, width: 130 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                    {/* PlayStoreButton */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://play.google.com/store/apps/details?id=com.searchmontage.app&hl=en-US&ah=nQPwrvIrI6Uex14KJwiIkePiWGQ'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 5,
                            flex: 1,
                            margin: 5,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={Images['PlayStoreButton']}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { height: 40, width: 130 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              </View>
            </BlurView>
          </View>
        )}
      </>
      {/* TourModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        transparent={true}
        visible={Constants['SHOW_HOMEPAGE_TOUR']}
      >
        <BlurView
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
            {
              height: dimensions.height,
              width: dimensions.width,
              alignItems: 'center',
              justifyContent: 'center',
            },
            dimensions.width
          )}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background.brand,
                borderColor: palettes.App['Border Color'],
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                opacity: 1,
                paddingBottom: 40,
                paddingTop: 40,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* CloseButton */}
            <IconButton
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'SHOW_HOMEPAGE_TOUR',
                    value: false,
                  });
                  setGlobalVariableValue({
                    key: 'SHOW_TOUR',
                    value: 0,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/closecircleo'}
              style={StyleSheet.applyWidth(
                { position: 'absolute', right: 8, top: 8 },
                dimensions.width
              )}
            />
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 16,
                  paddingBottom: 4,
                },
                dimensions.width
              )}
            >
              {/* HeaderText */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['ResponsiveNameText'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['ResponsiveNameText'].style,
                    {
                      color: theme.colors.text.strong,
                      marginBottom: 12,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Welcome to'}
              </Text>
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={Images['LogoPurpleFull']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 75, width: 225 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              {/* IntroText */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['ResponsiveNameText'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['ResponsiveNameText'].style,
                    {
                      color: theme.colors.text.strong,
                      marginBottom: 40,
                      marginTop: 40,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Would you like a tour of the app?'}
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'SHOW_HOMEPAGE_TOUR',
                        value: false,
                      });
                      setGlobalVariableValue({
                        key: 'SHOW_TOUR',
                        value: 0,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderColor: palettes.App['Custom #5f5a53'],
                        borderWidth: 1,
                        color: palettes.App['Custom #5f5a53'],
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'No thank you'}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* ConfirmButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      startTour();
                      setGlobalVariableValue({
                        key: 'SHOW_TOUR',
                        value: 1,
                      });
                      setGlobalVariableValue({
                        key: 'SHOW_HOMEPAGE_TOUR',
                        value: false,
                      });
                      /* hidden 'Run a Custom Function' action */
                      /* hidden 'Set Variable' action */
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: theme.colors.branding.primary,
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Yes!'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
      {/* LoginModal */}
      <>
        {!Constants['showLoginModal'] ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['LoginModalView'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['LoginModalView'].style,
                { height: dimensions.height, width: dimensions.width }
              ),
              dimensions.width
            )}
          >
            <BlurView
              intensity={50}
              tint={'default'}
              {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                  {
                    height: dimensions.height,
                    width: dimensions.width,
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                ),
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: '"rgba(255, 255, 255, 0.9)"',
                    borderColor: palettes.App['Border Color'],
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 400,
                    opacity: 1,
                    paddingBottom: 40,
                    paddingTop: 40,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* ModalHeader */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: 16,
                      paddingBottom: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={Images['LogoPurpleFull']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 75, width: 225 }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* ModalBody */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexGrow: 1, flexShrink: 0, padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: 'SpaceGrotesk_600SemiBold',
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Welcome! \n\nCreate an account to personalize your experience and save tags.'
                    }
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Desktop, value: 'row' },
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                        ],
                        justifyContent: {
                          minWidth: Breakpoints.Desktop,
                          value: 'space-between',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Desktop, value: 1 },
                            { minWidth: Breakpoints.Mobile, value: 1 },
                          ],
                          marginRight: [
                            { minWidth: Breakpoints.Desktop, value: 4 },
                            { minWidth: Breakpoints.Mobile, value: 4 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* SignupButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'API Request' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Navigate' action */
                            /* hidden 'Navigate' action */
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        disabled={isLoading}
                        loading={isLoading}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.branding.primary,
                            borderRadius: 8,
                            fontFamily: 'SpaceGrotesk_600SemiBold',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 16,
                            },
                            height: 50,
                            marginTop: 25,
                            textAlign: 'center',
                            width: {
                              minWidth: Breakpoints.Desktop,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                        title={'Signup'}
                      />
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Desktop, value: 1 },
                          ],
                          marginLeft: [
                            { minWidth: Breakpoints.Mobile, value: 4 },
                            { minWidth: Breakpoints.Desktop, value: 4 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* LoginButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.navigate('LoginScreen');
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'API Request' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Conditional Stop' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Navigate' action */
                            /* hidden 'Navigate' action */
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Outlined Button']
                          .props}
                        disabled={isLoading}
                        loading={isLoading}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Outlined Button']
                              .style,
                            {
                              fontFamily: 'SpaceGrotesk_600SemiBold',
                              fontSize: {
                                minWidth: Breakpoints.Desktop,
                                value: 16,
                              },
                              height: 50,
                              marginTop: 25,
                              width: {
                                minWidth: Breakpoints.Desktop,
                                value: '100%',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Log in'}
                      />
                    </View>
                  </View>
                  {/* ErrorMessage */}
                  <>
                    {!errorMessage ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.background.danger,
                            fontFamily: 'SpaceGrotesk_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 16,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {errorMessage}
                      </Text>
                    )}
                  </>
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 12 },
                            { minWidth: Breakpoints.Desktop, value: 14 },
                          ],
                          marginTop: 12,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'-- or --'}
                  </Text>
                  {/* SoicalLogins */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['SoicalLogins 2'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['SoicalLogins 2'].style,
                        { marginTop: 20 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* GoogleView */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      {/* GoogleAuthWrapper */}
                      <Utils.CustomCodeErrorBoundary>
                        <AuthComponents.GoogleLogin
                          navigation={props.navigation}
                          theme={props.theme}
                          setErrorMessage={setErrorMessage}
                          setIsLoading={setIsLoginWithGoogle}
                          isLoading={isLoginWithGoogle}
                          onLogin={() =>
                            setGlobalVariableValue({
                              key: 'showLoginModal',
                              value: false,
                            })
                          }
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                alignSelf: 'auto',
                                backgroundColor: theme.colors.background.brand,
                                borderColor: palettes.App['Border Color'],
                                borderRadius: 12,
                                borderWidth: 1,
                                flex: 1,
                                flexDirection: 'row',
                                height: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 60,
                                },
                                justifyContent: 'center',
                                maxHeight: 50,
                                paddingBottom: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <Image
                              resizeMode={'cover'}
                              {...GlobalStyles.ImageStyles(theme)['Image']
                                .props}
                              source={Images['Google']}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image']
                                    .style,
                                  { height: 22, width: 22 }
                                ),
                                dimensions.width
                              )}
                            />
                            <>
                              {!isLoginWithGoogle ? null : (
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  {...GlobalStyles.ActivityIndicatorStyles(
                                    theme
                                  )['Activity Indicator'].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ActivityIndicatorStyles(theme)[
                                      'Activity Indicator'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </View>
                        </AuthComponents.GoogleLogin>
                      </Utils.CustomCodeErrorBoundary>
                    </View>
                  </View>
                  {/* SkipForNow */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 30,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'showLoginModal',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { marginLeft: 8 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App.BackgroundPurple,
                            fontFamily: 'SpaceGrotesk_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Desktop, value: 16 },
                              { minWidth: Breakpoints.Mobile, value: 18 },
                            ],
                            textDecorationLine: 'underline',
                          },
                          dimensions.width
                        )}
                      >
                        {'Skip for now'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
              </View>
            </BlurView>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(HomepageScreen);