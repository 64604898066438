import React from 'react';

const updateGlobalIsLiked = (
  Variables,
  setGlobalVariableValue,
  id,
  newValue
) => {
  const isLikedByUserCache = Variables?.isLikedByUserCache || {};
  isLikedByUserCache[id] = newValue;
  setGlobalVariableValue({
    key: 'isLikedByUserCache',
    value: isLikedByUserCache,
  });
};

export default updateGlobalIsLiked;
