import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const changePasswordGET = async (
  Constants,
  { current_password, new_password },
  handlers = {}
) => {
  const paramsDict = {};
  if (current_password !== undefined) {
    paramsDict['current_password'] = renderParam(current_password);
  }
  if (new_password !== undefined) {
    paramsDict['new_password'] = renderParam(new_password);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/change_password${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useChangePasswordGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ChangePassword', args],
    () => changePasswordGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['ChangePasswords']),
    }
  );
};

export const FetchChangePasswordGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  current_password,
  new_password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useChangePasswordGET(
    { current_password, new_password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchChangePassword: refetch });
};

export const chatPOST = async (
  Constants,
  { message, receiver_id, tags_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/chat`;
  const options = {
    body: JSON.stringify({
      receiver_id: receiver_id,
      tags_id: tags_id,
      message: message,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useChatPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => chatPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Chat', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Chat');
        queryClient.invalidateQueries('Chats');
      },
    }
  );
};

export const FetchChatPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  message,
  receiver_id,
  tags_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useChatPOST(
    { message, receiver_id, tags_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchChat: refetch });
};

export const completeOnboardingPOST = async (
  Constants,
  {
    allow_emails,
    dob,
    gender,
    handle,
    interests_ids,
    location,
    name,
    profile_photo,
    push_token,
  },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/complete_onboarding`;
  const options = {
    body: JSON.stringify({
      name: name,
      handle: handle,
      push_token: push_token,
      allow_emails: allow_emails,
      profile_photo: profile_photo,
      gender: gender,
      location: location,
      dob: dob,
      interests_ids: interests_ids,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCompleteOnboardingPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      completeOnboardingPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Auth');
        queryClient.invalidateQueries('Auths');
      },
    }
  );
};

export const FetchCompleteOnboardingPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  allow_emails,
  dob,
  gender,
  handle,
  interests_ids,
  location,
  name,
  profile_photo,
  push_token,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCompleteOnboardingPOST(
    {
      allow_emails,
      dob,
      gender,
      handle,
      interests_ids,
      location,
      name,
      profile_photo,
      push_token,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCompleteOnboarding: refetch });
};

export const deleteAccountDELETE = async (
  Constants,
  { user_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/user/${encodeQueryParam(
    user_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteAccountDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAccountDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profiles', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const deleteCommentDELETE = async (
  Constants,
  { comments_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/comments/${encodeQueryParam(
    comments_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteCommentDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteCommentDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Comments', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Comment');
        queryClient.invalidateQueries('Comments');
      },
    }
  );
};

export const getCommentsGET = async (
  Constants,
  { page, perPage, user_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (user_id !== undefined) {
    paramsDict['user_id'] = renderParam(user_id);
  }
  if (perPage !== undefined) {
    paramsDict['perPage'] = renderParam(perPage);
  }
  if (page !== undefined) {
    paramsDict['page'] = renderParam(page);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/comments${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCommentsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Comments', args],
    () => getCommentsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetCommentsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  page,
  perPage,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCommentsGET(
    { page, perPage, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetComments: refetch });
};

export const getCommentsOfTagsGET = async (
  Constants,
  { tag_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/comments/${encodeQueryParam(
    tag_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCommentsOfTagsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Comment', args],
    () => getCommentsOfTagsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Comments']),
    }
  );
};

export const FetchGetCommentsOfTagsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tag_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCommentsOfTagsGET(
    { tag_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCommentsOfTags: refetch });
};

export const getDiscoverDataGET = async (
  Constants,
  { promoCount },
  handlers = {}
) => {
  const paramsDict = {};
  if (promoCount !== undefined) {
    paramsDict['promo_count'] = renderParam(promoCount);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/get_discover_data${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetDiscoverDataGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['DiscoverPage', args],
    () => getDiscoverDataGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['DiscoverPages']),
    }
  );
};

export const FetchGetDiscoverDataGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promoCount,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetDiscoverDataGET(
    { promoCount },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetDiscoverData: refetch });
};

export const getInterestsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/interests`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetInterestsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Interest', args],
    () => getInterestsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Interests']),
    }
  );
};

export const FetchGetInterestsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetInterestsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetInterests: refetch });
};

export const getPlatformVersionGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/platform_version`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPlatformVersionGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newXANOAPIGetPlatformVersionGET', args],
    () => getPlatformVersionGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['newXANOAPIGetPlatformVersionGETS']),
    }
  );
};

export const FetchGetPlatformVersionGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPlatformVersionGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPlatformVersion: refetch });
};

export const getProfileDataGET = async (
  Constants,
  { reload, user_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (reload !== undefined) {
    paramsDict['reload'] = renderParam(reload);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/profile/${encodeQueryParam(
    user_id
  )}${renderQueryString(paramsDict)}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetProfileDataGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Profile', args],
    () => getProfileDataGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Profiles']),
    }
  );
};

export const FetchGetProfileDataGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  reload,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProfileDataGET(
    { reload, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProfileData: refetch });
};

export const getOTPPOST = async (Constants, { email }, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/forgot_password/initiate`;
  const options = {
    body: JSON.stringify({ email: email }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetOTPPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => getOTPPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('ForgotPassword', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('ForgotPassword');
        queryClient.invalidateQueries('ForgotPasswords');
      },
    }
  );
};

export const FetchGetOTPPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGetOTPPOST(
    { email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetOTP: refetch });
};

export const isUniqueEmailGET = async (Constants, { email }, handlers = {}) => {
  const paramsDict = {};
  if (email !== undefined) {
    paramsDict['email'] = renderParam(email);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/unique_email_check${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useIsUniqueEmailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newXANOAPIIsUniqueEmailGET', args],
    () => isUniqueEmailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['newXANOAPIIsUniqueEmailGETS']),
    }
  );
};

export const FetchIsUniqueEmailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useIsUniqueEmailGET(
    { email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchIsUniqueEmail: refetch });
};

export const isUniqueHandleGET = async (
  Constants,
  { handle },
  handlers = {}
) => {
  const paramsDict = {};
  if (handle !== undefined) {
    paramsDict['handle'] = renderParam(handle);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/unique_handle_check${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useIsUniqueHandleGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newXANOAPIIsUniqueHandleGET', args],
    () => isUniqueHandleGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['newXANOAPIIsUniqueHandleGETS']),
    }
  );
};

export const FetchIsUniqueHandleGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  handle,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useIsUniqueHandleGET(
    { handle },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchIsUniqueHandle: refetch });
};

export const loginPOST = async (
  Constants,
  { email, password },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/auth/login`;
  const options = {
    body: JSON.stringify({ email: email, password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newXANOAPILoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['newXANOAPILoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const meGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/auth/me`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['Auth', args], () => meGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['Auths']),
  });
};

export const FetchMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMe: refetch });
};

export const postCommentPOST = async (
  Constants,
  { comment, tags_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/comments`;
  const options = {
    body: JSON.stringify({ tags_id: tags_id, comment: comment }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePostCommentPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => postCommentPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Comments', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Comment');
        queryClient.invalidateQueries('Comments');
      },
    }
  );
};

export const FetchPostCommentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  comment,
  tags_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePostCommentPOST(
    { comment, tags_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPostComment: refetch });
};

export const signupPOST = async (
  Constants,
  {
    allow_emails,
    dob,
    email,
    gender,
    handle,
    interests_ids,
    location,
    name,
    password,
    profile_photo,
    push_token,
  },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/auth/signup`;
  const options = {
    body: JSON.stringify({
      email: email,
      password: password,
      name: name,
      handle: handle,
      gender: gender,
      location: location,
      dob: dob,
      interests_ids: interests_ids,
      push_token: push_token,
      allow_emails: allow_emails,
      profile_photo: profile_photo,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSignupPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['Auth', args], () => signupPOST(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['Auths']),
  });
};

export const FetchSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  allow_emails,
  dob,
  email,
  gender,
  handle,
  interests_ids,
  location,
  name,
  password,
  profile_photo,
  push_token,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSignupPOST(
    {
      allow_emails,
      dob,
      email,
      gender,
      handle,
      interests_ids,
      location,
      name,
      password,
      profile_photo,
      push_token,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignup: refetch });
};

export const toggleBlockGET = async (Constants, { user_id }, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/toggle_block_user/${encodeQueryParam(
    user_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleBlockGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newXANOAPIToggleBlockGET', args],
    () => toggleBlockGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['newXANOAPIToggleBlockGETS']),
    }
  );
};

export const FetchToggleBlockGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useToggleBlockGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleBlock: refetch });
};

export const toggleCommentLikePOST = async (
  Constants,
  { comment_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/comment_likes`;
  const options = {
    body: JSON.stringify({ comment_id: comment_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleCommentLikePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      toggleCommentLikePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Comments', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Comment');
        queryClient.invalidateQueries('Comments');
      },
    }
  );
};

export const FetchToggleCommentLikePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  comment_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useToggleCommentLikePOST(
    { comment_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleCommentLike: refetch });
};

export const toggleEmailPermissionGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/toggle_emails`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleEmailPermissionGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Profile', args],
    () => toggleEmailPermissionGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Profiles']),
    }
  );
};

export const FetchToggleEmailPermissionGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useToggleEmailPermissionGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchToggleEmailPermission: refetch,
  });
};

export const togglePushNotificationPOST = async (
  Constants,
  { push_token },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/toggle_notifications`;
  const options = {
    body: JSON.stringify({ push_token: push_token }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTogglePushNotificationPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      togglePushNotificationPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profiles', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchTogglePushNotificationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  push_token,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useTogglePushNotificationPOST(
    { push_token },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchTogglePushNotification: refetch,
  });
};

export const toggleFollowPOST = async (
  Constants,
  { following_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/follow`;
  const options = {
    body: JSON.stringify({ following_id: following_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleFollowPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => toggleFollowPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profiles', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchToggleFollowPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  following_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useToggleFollowPOST(
    { following_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleFollow: refetch });
};

export const toggleLikePOST = async (Constants, { tag_id }, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/likes`;
  const options = {
    body: JSON.stringify({ tag_id: tag_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleLikePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['newXANOAPIToggleLikePOST', args],
    () => toggleLikePOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['newXANOAPIToggleLikePOSTS']),
    }
  );
};

export const FetchToggleLikePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tag_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useToggleLikePOST(
    { tag_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleLike: refetch });
};

export const updateBioPOST = async (Constants, { bio }, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/update_bio`;
  const options = {
    body: JSON.stringify({ bio: bio }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateBioPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateBioPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profiles', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchUpdateBioPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bio,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateBioPOST(
    { bio },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateBio: refetch });
};

export const updateNamePOST = async (Constants, { name }, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/update_name`;
  const options = {
    body: JSON.stringify({ name: name }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateNamePOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateNamePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profiles', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchUpdateNamePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateNamePOST(
    { name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateName: refetch });
};

export const updateProfilePhotoPOST = async (
  Constants,
  { profile_photo },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/update_profile_photo`;
  const options = {
    body: JSON.stringify({ profile_photo: profile_photo }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateProfilePhotoPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateProfilePhotoPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profiles', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchUpdateProfilePhotoPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  profile_photo,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateProfilePhotoPOST(
    { profile_photo },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateProfilePhoto: refetch });
};

export const updatePasswordPOST = async (
  Constants,
  { password },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/forgot_password/update`;
  const options = {
    body: JSON.stringify({ password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdatePasswordPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePasswordPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('ForgotPassword', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('ForgotPassword');
        queryClient.invalidateQueries('ForgotPasswords');
      },
    }
  );
};

export const FetchUpdatePasswordPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdatePasswordPOST(
    { password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdatePassword: refetch });
};

export const verifyOTPGET = async (
  Constants,
  { code, email },
  handlers = {}
) => {
  const paramsDict = {};
  if (email !== undefined) {
    paramsDict['email'] = renderParam(email);
  }
  if (code !== undefined) {
    paramsDict['code'] = renderParam(code);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/forgot_password/verify${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useVerifyOTPGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ForgotPassword', args],
    () => verifyOTPGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['ForgotPasswords']),
    }
  );
};

export const FetchVerifyOTPGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  code,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useVerifyOTPGET(
    { code, email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchVerifyOTP: refetch });
};
