import palettes from '../themes/palettes';
import React, { useState, useEffect, useRef } from 'react';
import {
  ActivityIndicator,
  Text,
  View,
  TouchableOpacity,
  Platform,
  useWindowDimensions,
} from 'react-native';
import { Card } from './UI';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as Animatable from 'react-native-animatable';
import { useAnalytics } from '@segment/analytics-react-native';
import { MasonryList } from './MasonryList';

export const List = ({
  theme,
  navigation,
  endPoint = 'get_tags_noauth', // 'get_tags','get_tags_noauth', 'get_related_tags', 'get_related_tags_no_auth', 'user_tags', 'liked_tags',
  searchQuery = '',
  promotionId,
  tags_id,
  id,
  refreshData,
  showDownloadModal,
}) => {
  const variables = GlobalVariables.useValues();
  const setVariables = GlobalVariables.useSetValue();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const segment = useAnalytics();
  const baseUrl = 'https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK';

  const dimensions = useWindowDimensions();
  const listRef = useRef();

  const numColumns =
    dimensions.width < Breakpoints.Tablet
      ? 2
      : dimensions.width < Breakpoints.Laptop
      ? 3
      : 4;

  const fetchData = async (page, perPage) => {
    try {
      let queryUrl = '';

      if (endPoint === 'get_tags') {
        queryUrl = `${baseUrl}/get_tags?page=${page || 1}&perPage=${
          perPage || 15
        }
        ${searchQuery ? `&searchQuery=${searchQuery.replace(/’/g, "'")}` : ''}
        ${promotionId ? `&promotion_id=${promotionId}` : ''}`;
      }

      if (endPoint === 'get_tags_noauth') {
        queryUrl = `${baseUrl}/get_tags_noauth?page=${page || 1}&perPage=${
          perPage || 15
        }
        ${searchQuery ? `&searchQuery=${searchQuery.replace(/’/g, "'")}` : ''}
        ${promotionId ? `&promotion_id=${promotionId}` : ''}`;
      } else if (endPoint === 'get_related_tags') {
        queryUrl = `${baseUrl}/get_related_tags?page=${page || 1}&perPage=${
          perPage || 15
        }&tags_id=${tags_id | 1}`;
      } else if (endPoint === 'get_related_tags_no_auth') {
        queryUrl = `${baseUrl}/get_related_tags_noauth?page=${
          page || 1
        }&perPage=${perPage || 15}&tags_id=${tags_id | 1}`;
      } else if (endPoint === 'liked_tags') {
        queryUrl = `${baseUrl}/liked_tags_with_ads/${id}?page=${
          page || 1
        }&perPage=${perPage || 15}`;
      } else if (endPoint === 'user_tags') {
        queryUrl = `${baseUrl}/user_tags_with_ads/${id}?page=${
          page || 1
        }&perPage=${perPage || 15}`;
      }

      // console.log('73', endPoint, queryUrl)

      const tagsResponse = await fetch(queryUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: variables?.AUTH_TOKEN,
        },
      });
      return (await tagsResponse.json()) || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setVariables({
          key: 'isHomepageRefreshing',
          value: true,
        });

        const newItems = await fetchData(1, 25);
        const itemsResult = newItems?.items || newItems?.result.items || [];
        updateCaches(itemsResult);

        const adsData = newItems?.ads?.map(item => ({
          ...item,
          type: 'ad',
          uniqueId: Math.round(Math.random() * 1000000),
        }));

        if (adsData) {
          let n = Math.floor(itemsResult.length / adsData.length);
          for (let i = 0; i < adsData.length; i++) {
            itemsResult.splice(n * (i + 1), 0, adsData[i]);
          }
        }
        setItems(itemsResult);
        setCurPage(newItems?.nextPage || newItems?.result?.nextPage);
        setVariables({
          key: 'isHomepageRefreshing',
          value: false,
        });

        setIsLoading(false);
        if (refreshData) {
          listRef.current?.scrollTo({
            y: 0,
            animated: true,
          });
        }
      } catch (err) {
        console.error(err);
        // Alert.alert('An error occurred', 'Unable to fetch data.');
      }
    })();
  }, [refreshData, promotionId, searchQuery]);

  const updateCaches = async itemsResult => {
    const likesCache = variables?.likesCache || {};
    const isLikedByUserCache = variables.isLikedByUserCache || {};
    const sharesCache = variables?.sharesCache || {};

    await itemsResult.forEach(item => {
      const tagID = endPoint === 'liked_tags' ? item.tags_id : item.id;
      likesCache[tagID] =
        endPoint === 'liked_tags'
          ? item?._tags?._likes_of_tags
          : item?._likes_of_tags;
      likesCache[tagID] = Math.max(likesCache[tagID], 0);

      sharesCache[tagID] =
        endPoint === 'liked_tags'
          ? item?._tags?._share_count
          : item?._share_count;

      isLikedByUserCache[tagID] =
        endPoint === 'liked_tags'
          ? item?._tags?._is_liked_by_user
          : item?._is_liked_by_user;
    });

    setVariables({ key: 'likesCache', value: likesCache });
    setVariables({ key: 'isLikedByUserCache', value: isLikedByUserCache });
    setVariables({ key: 'sharesCache', value: sharesCache });
  };

  const generateSkeletonLayout = () => {
    const layout = [];
    const numCards = numColumns === 2 ? 6 : numColumns === 3 ? 12 : 24;
    const minHeight = 300;
    const maxHeight = 700;
    const heightScale = 0.5;

    const cardHeights = Array.from({ length: numCards }, () => {
      const randomHeight = Math.random() * (maxHeight - minHeight) + minHeight;
      return randomHeight * heightScale;
    });
    const width =
      (Math.min(dimensions.width, 1024) - 6 * (numColumns * 2)) / numColumns;

    for (let i = 0; i < numCards; i++) {
      layout.push({
        key: i,
        width: width,
        height: cardHeights[i],
        margin: 6,
        borderRadius: 5,
      });
    }

    return layout;
  };

  const [renderedItemLayouts, setRenderedItemLayouts] = useState({});
  const [visibleItems, setVisibleItems] = useState([]);

  const handleScroll = ({ nativeEvent }) => {
    // check each item in renderedItemLayouts to see if it is visible, set the visibleItems with the ids of the visible items
    const { contentOffset, layoutMeasurement } = nativeEvent;
    const { y: scrollY } = contentOffset;
    const { height } = layoutMeasurement;
    const vi = [];

    if (Object.keys(renderedItemLayouts).length === 0) return;

    Object.keys(renderedItemLayouts).forEach(key => {
      const { y: itemY, height: itemHeight } = renderedItemLayouts[key];
      const isVisible =
        itemY - scrollY >= 0 && itemY - scrollY <= height - itemHeight;

      if (isVisible) vi.push(parseInt(key));
    });
    setVisibleItems(vi);
  };

  if (variables?.isHomepageRefreshing) {
    return (
      <View
        style={{
          flex: 1,
          flexWrap: 'wrap',
        }}
      >
        {generateSkeletonLayout().map((item, index) => {
          return (
            <Animatable.View
              animation={{
                0: {
                  opacity: 0,
                },
                0.5: {
                  opacity: 0.5,
                },
                to: {
                  opacity: 0,
                },
              }}
              easing="ease-in-out-sine"
              duration={2500}
              iterationCount="infinite"
              style={{
                width: item.width,
                height: item.height,
                margin: item.margin,
                borderRadius: item.borderRadius,
                backgroundColor: 'rgb(187, 195, 203)',
                borderColor: 'rgb(187, 195, 203)',
              }}
            ></Animatable.View>
          );
        })}
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <MasonryList
        data={items}
        innerRef={listRef}
        keyExtractor={item => item.id}
        numColumns={numColumns}
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        renderItem={({ item, index }) => {
          const cardItem =
            endPoint === 'liked_tags' && item.type !== 'ad' ? item._tags : item;
          return (
            <TouchableOpacity
              onPress={() => {
                if (item.type !== 'ad') {
                  if (cardItem.validated) {
                    navigation.navigate('BottomTabNavigator', {
                      screen: 'HomeStackNavgiator',
                      params: {
                        screen: 'ClickedTagPageScreen',
                        params: {
                          tagID: cardItem.id,
                        },
                      },
                      key:
                        'ClickedTagPageScreen' +
                        Math.round(Math.random() * 1000000),
                    });
                  } else {
                    navigation.navigate('BottomTabNavigator', {
                      screen: 'HomeStackNavgiator',
                      params: {
                        screen: 'UnverifiedTagPreviewScreen',
                        params: {
                          tagID: cardItem.id,
                        },
                      },
                      key:
                        'UnverifiedTagPreviewScreen' +
                        Math.round(Math.random() * 1000000),
                    });
                  }
                }
              }}
              style={{ marginBottom: Platform.OS === 'web' ? 8 : undefined }}
              key={`${item.type === 'ad' ? 'ad' : 'card'}-${item.id}-${index}`}
              onLayout={e => {
                if (item.type !== 'ad') {
                  if (renderedItemLayouts.length > 0)
                    setRenderedItemLayouts({});

                  return;
                }

                const { y, height } = e.nativeEvent.layout;
                setRenderedItemLayouts(prev => {
                  return {
                    ...prev,
                    [item.uniqueId]: { y, height },
                  };
                });
              }}
            >
              <Card
                item={cardItem}
                theme={theme}
                navigation={navigation}
                showDownloadModal={showDownloadModal}
                index={index}
                isVisible={visibleItems.includes(item.uniqueId)}
              />
            </TouchableOpacity>
          );
        }}
        refreshing={isLoading}
        onRefresh={() => {
          (async () => {
            try {
              setVariables({
                key: 'isHomepageRefreshing',
                value: true,
              });
              setIsLoading(true);
              const newItems = await fetchData(1, 25);

              const itemsResult =
                newItems?.items || newItems?.result.items || [];
              updateCaches(itemsResult);

              const adsData = newItems?.ads?.map(item => ({
                ...item,
                type: 'ad',
                uniqueId: Math.round(Math.random() * 1000000),
              }));

              if (adsData) {
                let n = Math.floor(itemsResult.length / adsData.length);
                for (let i = 0; i < adsData.length; i++) {
                  itemsResult.splice(n * (i + 2), 0, adsData[i]);
                }
              }
              setItems(itemsResult);
              setCurPage(newItems?.nextPage || newItems?.result?.nextPage);
              setVariables({
                key: 'isHomepageRefreshing',
                value: false,
              });

              setIsLoading(false);

              listRef.current?.scrollTo({
                y: 0,
                animated: true,
              });
            } catch (err) {
              console.error(err);
              // Alert.alert('An error occurred', 'Unable to fetch data.');
            }
          })();
        }}
        // LoadingView={() => <ActivityIndicator />}
        ListEmptyComponent={() => {
          if (searchQuery)
            return (
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: dimensions.height / 3,
                }}
              >
                <Text
                  style={{
                    color: theme.colors.background.brand,
                    fontSize: 16,
                    textAlign: 'center',
                  }}
                >
                  Your search did not return any results. Try searching for a TV
                  network, TV show, actor, or clothing brand
                </Text>
              </View>
            );
          else {
            return (
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: dimensions.height / 3,
                }}
              >
                <Text
                  style={{
                    color: theme.colors.background.brand,
                    fontSize: 16,
                    textAlign: 'center',
                  }}
                >
                  {endPoint == 'liked_tags'
                    ? 'This user has no liked tags.'
                    : 'No tags found '}
                </Text>
              </View>
            );
          }

          return null;
        }}
        onEndReachedThreshold={0.1}
        onEndReached={() => {
          (async () => {
            try {
              if (isLoading) return;
              if (!curPage) return;
              setIsLoading(true);
              const newItems = await fetchData(curPage, 25);
              const itemsResult = newItems?.items || newItems?.result.items;

              updateCaches(itemsResult);

              const adsData = newItems?.ads?.map(item => ({
                ...item,
                type: 'ad',
                uniqueId: Math.round(Math.random() * 1000000),
              }));

              if (adsData) {
                let n = Math.floor(itemsResult.length / adsData.length);
                for (let i = 0; i < adsData.length; i++) {
                  itemsResult.splice(n * (i + 2), 0, adsData[i]);
                }
              }

              setItems([...items, ...itemsResult]);
              setCurPage(newItems?.nextPage || newItems?.result?.nextPage);
              setIsLoading(false);
            } catch (err) {
              console.error(err);
              // Alert.alert('An error occurred', 'Unable to fetch more data.');
            }
          })();
        }}
        onScroll={handleScroll}
      />
      {isLoading && (
        <View style={{ marginVertical: 8 }}>
          <ActivityIndicator />
        </View>
      )}
    </View>
  );
};
