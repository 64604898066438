import React from 'react';

const formatGoToHomepageParams = (pid, query, initialFacets) => {
  const params = {};
  if (pid !== null && pid !== undefined) params.pid = pid;
  if (query !== null && query !== undefined) params.query = query;
  if (initialFacets) params.initialFacets = initialFacets;
  return params;
};

export default formatGoToHomepageParams;
