import React from 'react';

const MergeUserInfo = (
  Variables,
  setGlobalVariableValue,
  profileOfUser,
  rootData
) => {
  if (profileOfUser) {
    setGlobalVariableValue({
      key: 'USER',
      value: {
        ...Variables?.USER,
        _profile_of_user: profileOfUser,
      },
    });
  } else {
    setGlobalVariableValue({
      key: 'USER',
      value: {
        ...Variables?.USER,
        ...rootData,
      },
    });
  }
};

export default MergeUserInfo;
