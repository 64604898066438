import React from 'react';

const updateGlobalLikes = (Variables, setGlobalVariableValue, id, newValue) => {
  const likesCache = Variables?.likesCache || {};
  likesCache[id] = newValue;
  likesCache[id] = Math.max(likesCache[id], 0);
  setGlobalVariableValue({ key: 'likesCache', value: likesCache });
};

export default updateGlobalLikes;
